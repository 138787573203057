import axios from "utils/axios";

// Maximum file size (10MB)
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

// Utility function to validate file size
export const isFileSizeValid = (files, existingSize) => {
    const sizeOfSelectedFiles = Array.from(files).reduce((acc, file) => acc + file.size, 0);
    const totalSize = sizeOfSelectedFiles + existingSize;
    return totalSize < MAX_FILE_SIZE;
};

// Utility function to read files and convert them to base64
export const readAndConvertFiles = (files) => {
    return Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;
                const encryptedContent = btoa(fileContent); // Encrypt in Base64
                let contentType = file.type;
                if (!contentType && file.name.endsWith(".dwg")) {
                    contentType = "application/acad"; // Handle .dwg files
                }
                resolve({
                    Name: file.name,
                    Content: encryptedContent,
                    ContentType: contentType,
                    FileSize: file.size,
                });
            };
            reader.readAsBinaryString(file);
        });
    });
};

// Material-specific file upload click handler
export const handleMaterialFileUploadClick = (event) => {
    event.target.value = ""; // Clear file input on click
};

// Material-specific file upload handler
export const handleMaterialFileUpload = async (event, materialIndex, setFieldValue, values, setMaterialFilesSkeleton, setMaterialUploadsErrors, setOpenMaterialFileSizeError, materialFilesTotalSize) => {
    const files = event.target.files;
    const totalExistingSize = materialFilesTotalSize.reduce((acc, file) => acc + file.FileSize, 0);

    if (isFileSizeValid(files, totalExistingSize)) {
        setMaterialFilesSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

        const convertedFilesPromises = readAndConvertFiles(files);

        try {
            const convertedFiles = await Promise.all(convertedFilesPromises);
            const response = await axios.post("/api/rfqs/upload/attachments", convertedFiles);

            // Update the Formik state to include attachments for the specific material
            const currentAttachments = values.materials[materialIndex]?.attachments || [];
            const updatedAttachments = [...currentAttachments, ...response.data];

            setFieldValue(`materials[${materialIndex}].attachments`, updatedAttachments);
            setMaterialFilesSkeleton([]);
        } catch (error) {
            setMaterialFilesSkeleton([]);
            setMaterialUploadsErrors(error.message);
            setTimeout(() => setMaterialUploadsErrors(""), 5000);
        }
    } else {
        setOpenMaterialFileSizeError(true);
        setTimeout(() => setOpenMaterialFileSizeError(false), 6000);
    }
};

// Handle deleting material-specific files
// Handle deleting material-specific files
export const handleDeleteMaterialFile = (name, event, setFieldValue, selectedMaterialIndex, values, setMaterialFilesBase, setMaterialFilesTotalSize) => {
    event.preventDefault();

    // Ensure that `values.materials[selectedMaterialIndex]` exists
    const currentMaterial = values.materials[selectedMaterialIndex];

    if (currentMaterial) {
        setMaterialFilesBase((prevVal) => prevVal.filter((file) => file.Name !== name));
        setMaterialFilesTotalSize((prevVal) => prevVal.filter((file) => file.Name !== name));

        // Safely update Formik state to remove the file
        setFieldValue(
            `materials[${selectedMaterialIndex}].attachments`,
            currentMaterial.attachments.filter((file) => file.Name !== name)
        );
    }
};


// Handle closing the file size error for materials
export const handleCloseMaterialFileSizeError = (setOpenMaterialFileSizeError) => {
    setOpenMaterialFileSizeError(false);
};
