import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "utils/axios";
import Skeleton from "@mui/material/Skeleton"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { submitLabourQuotation, submitProvidingQuotation } from "actions/vendorQuotationAction";
import { useSnackbar } from 'notistack';
import CustomGstDropdown from "./CustomGstDropdown";

const validationSchema = Yup.object().shape({
    comments: Yup.string().required("Comments/Methods are required"),
    basePrice: Yup.number()
        .required("Base Price is required")
        .positive("Base Price must be a positive number")
        .typeError("Base Price must be a number"),
    gst: Yup.number()
        .required("GST is required")
        .min(0, "GST cannot be negative")
        .max(100, "GST cannot be more than 100")
        .typeError("GST must be a number"),
    deliveryDate: Yup.date().required("Delivery Date is required"),
    workStartDate: Yup.date().required("Work Start Date is required"),

});

const ProvidingAndFixing = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading, error, errorMessage, quotation } = useSelector((state) => state.vendorQuotation);
    const { enqueueSnackbar } = useSnackbar();
    const rfqData = location.state?.rfqData;


    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isCounterTerm, setIsCounterTerm] = useState(false);
    const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
    const [selectedFilesBase, setSelectedFilesBase] = useState([]);
    const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
    const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState('');
    const [openFilesSizeError, setOpenFilesSizeError] = useState(false);

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes


    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const attachedDocument = [
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
        { label: "HCIM/CBIS Bar-FE500 Disk", imgSrc: "" },
    ];

    const handleCloseForm = () => {
        navigate(`/leads/${rfqData?.rfqId}`)
    }

    // Function to calculate total amount
    const calculateTotalAmount = (basePrice, gst) => {
        return basePrice + (basePrice * gst / 100);
    };

    // Handle change functions
    const handleBasePriceChange = (e, setFieldValue, values, setTotalAmount) => {
        const basePrice = parseFloat(e.target.value) || 0;
        setFieldValue("basePrice", basePrice);
        setTotalAmount(calculateTotalAmount(basePrice, parseFloat(values.gst) || 0));
    };

    const handleGstChange = (e, setFieldValue, values, setTotalAmount) => {
        const gst = parseFloat(e.target.value) || 0;
        setFieldValue("gst", gst);
        setTotalAmount(calculateTotalAmount(parseFloat(values.basePrice) || 0, gst));
    };

    const handleFileUploadClick = (event) => {
        event.target.value = '';
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => acc + obj.size, 0);
        const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce((acc, obj) => acc + obj.FileSize, sizeOfSelectedFiles);

        if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
            setAttachmentsSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

            const convertedFilesPromises = Array.from(files).map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const fileContent = e.target.result;
                        const encryptedContent = btoa(fileContent); // Encrypt file content in Base64
                        let contentType = file.type;
                        if (!contentType && file.name.endsWith('.dwg')) {
                            contentType = 'application/acad'; // MIME type for DWG files
                        }
                        const payload = {
                            Name: file.name,
                            Content: encryptedContent,
                            ContentType: contentType,
                            FileSize: file.size,
                        };
                        resolve(payload);
                    };
                    reader.readAsBinaryString(file);
                });
            });

            Promise.all(convertedFilesPromises)
                .then((convertedFiles) => attachmentBlobLink(convertedFiles))
                .catch((error) => console.error(error));
        } else {
            setOpenFilesSizeError(true);
            setTimeout(() => setOpenFilesSizeError(false), 6000);
        }
    };

    const handleDeleteSelectedFile = (name, event) => {
        event.preventDefault();
        setSelectedFilesBase((prevVal) => prevVal.filter((data) => data.Name !== name));
        setSelectedFilesTotalSize((prevVal) => prevVal.filter((data) => data.Name !== name));
    };

    const handleCloseFileSizeError = () => {
        setOpenFilesSizeError(false);
    };

    const attachmentBlobLink = (convertedFiles) => {
        axios.post('/api/rfqs/upload/attachments', convertedFiles)
            .then((response) => {
                setSelectedFilesBase((prevVal) => [...prevVal, ...response.data]);
                setSelectedFilesTotalSize((prevVal) => [...prevVal, ...convertedFiles]);
                setAttachmentsSkeleton([]);
            })
            .catch((error) => {
                setAttachmentsSkeleton([]);
                setAttachmentsUploadsErrors(error.message);
                setTimeout(() => setAttachmentsUploadsErrors(''), 5000);
                console.error(error);
            });
    };

    const charges = [
        { label: "Transport", field1: "", field2: "" },
        { label: "Loading/Offloading (W/GST)", field1: "", field2: "" },
        { label: "Mathade Charges (W/GST)", field1: "", field2: "" },
        { label: "Misc. Charges (W/GST)", field1: "", field2: "" },
    ];

    useEffect(() => {
        if (!loading && quotation && Object.keys(quotation).length > 0) {
            // Show success snackbar when quotation is successfully submitted
            enqueueSnackbar('Vendor quotation submitted successfully!', {
                variant: 'success',
                autoHideDuration: 1000,
            });
        }

        if (!loading && error) {
            // Show error snackbar when there's an error
            enqueueSnackbar(`Error: ${errorMessage}`, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }, [loading, quotation, error, errorMessage, enqueueSnackbar]);

    return (

        <Formik
            initialValues={{
                comments: '', basePrice: '', gst: '', deliveryDate: '', workStartDate: '', paymentTerms: true, // true if "Yes" is selected
                counterPaymentTerms: "",
                notes: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {

                const payload = { ...values, selectedFilesBase }
                try {
                    console.log(payload);
                    await dispatch(submitProvidingQuotation({ payload }));
                    // Optionally handle success state here
                } catch (error) {
                    // Handle errors here, such as showing an error message
                    console.error("Submission failed:", error);
                }
            }}
        >
            {({ setFieldValue, values }) => (

                <div className="max-w-7xl mx-auto  bg-white shadow-md " style={{ fontFamily: "goldman_sans" }}>
                    {/* Header Section */}
                    <div className=" xl:grid xl:grid-cols-2 flex items-center flex-col-reverse xl:px-8   xl:py-4 shadow-md sticky top-0 bg-white z-10">
                        <div >
                            <div className="xl:flex items-center hidden ">
                                <h1 className="text-[28px] text-[#434343] font-semibold ">Add Proposal: Rs. </h1>
                                <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span>
                            </div>

                            <div className="">

                                {/* <div className="flex items-center space-x-4">
                            <p className="text-[#737373] font-bold hidden xl:block">Contact Name : <span className="font-normal">Vishal Rana</span></p>
                            <p className="text-[#737373] font-bold hidden xl:block">Contact Phone : <span className="font-normal">8826386281</span></p>
                            </div> */}
                                {/* <p className="text-gray-500">Viman Nagar, Pune, 411014, MH</p> */}
                            </div>
                        </div>
                        <div className="flex justify-center items-center xl:items-start xl:justify-start my-4">
                            {/* <span class="relative flex items-center justify-center h-9 w-9 ">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-[18px] w-[18px] bg-lime-500"></span>
                         </span> */}

                            <div className="xl:w-9 xl:h-9 w-6 h-6 mx-3 flex justify-center items-center rounded-full bg-[#DFF1C8] opacity-95">
                                <div className="xl:w-[18px] xl:h-[18px] w-3 h-3 rounded-full bg-lime-500"></div>
                            </div>

                            <h3 className="xl:text-2xl xl:mt-1 text-center text-sm text-[#737373]">Live Rank: <span className="font-bold text-black">L2</span></h3>
                            <div>
                                {/* <p className="text-[#9F9F9F] text-base italic hidden xl:block">Your rank will update as soon as the<br /> buyer receives atleast 3 ratings</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap xl:hidden gap-2 p-4 xl:p-0">
                        <h2 className="text-xs xl:text-2xl w-fit px-4 py-2 xl:p-0 text-[#434343] font-semibold bg-[#E9E9E1] xl:bg-white"><span className="xl:hidden font-normal">Client : </span> Vascon Engineers Pvt Ltd.</h2>
                        <h2 className="xl:hidden text-xs xl:text-2xl w-fit px-4 py-2 xl:p-0 text-[#434343] font-semibold bg-[#E9E9E1] xl:bg-white"><span className="xl:hidden font-normal">Delivery Date : </span> 10-10-2024</h2>
                        <h2 className="xl:hidden text-xs xl:text-2xl w-fit px-4 py-2 xl:p-0 text-[#434343] font-semibold bg-[#E9E9E1] xl:bg-white"><span className="xl:hidden font-normal">Status : </span> {rfqData.status}</h2>
                        <h2 className="xl:hidden text-xs xl:text-2xl w-fit px-4 py-2 xl:p-0 text-[#434343] font-semibold bg-[#E9E9E1] xl:bg-white"><span className="xl:hidden font-normal">Requirement : </span>{rfqData?.rfqType} </h2>
                    </div>

                    {/* rfq information */}
                    <div className="px-4 xl:py-[24px] xl:px-8">
                        <div className=" mt-4 mb-1 xl:hidden flex justify-between space-x-10">
                            <h3 className="text-xl font-semibold text-[#3B3B3B]">{rfqData?.name}</h3>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Bookmark.svg" alt="save" className="self-start" />
                        </div>
                        <p className="text-sm font-normal text-[#787878] xl:hidden">10-10-2024</p>
                        <h3 className="text-[32px] font-semibold hidden xl:block pb-2">RFQ Information</h3>
                        <p className="text-sm font-medium text-[#787878] mb-2 mt-6 xl:hidden">RFQ Summary</p>
                        <div className="flex flex-col-reverse xl:grid xl:grid-cols-3 xl:gap-x-8 xl:gap-y-4 border shadow-lg bg-white p-5 xl:py-[28px] xl:px-[20px]">
                            <div className="xl:space-y-5 space-y-4">
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal hidden xl:block">RFQ Name</p>
                                    <p className="text-[#4F4F42] text-base font-bold hidden xl:block">{rfqData?.name}</p>
                                </div>
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal  hidden xl:block">Work Start Date</p>
                                    <p className="text-[#4F4F42] text-base font-bold hidden xl:block">05/09/24</p>
                                </div>
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal">Payment Terms</p>
                                    <p className="text-[#4F4F42] text-base font-bold bg-[#ECECEC] xl:bg-white mt-1 p-4 xl:m-0 xl:p-0">90 Days PDC</p>
                                </div>

                            </div>
                            <div className="xl:space-y-5 space-y-4">
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal  hidden xl:block">Requirement Type</p>
                                    <p className="text-[#4F4F42] text-base font-bold  hidden xl:block">{rfqData?.rfqType}</p>
                                </div>
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal">Project Name</p>
                                    <p className="text-[#4F4F42] text-base font-bold">{rfqData?.projectName}</p>
                                </div>
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal">Location</p>
                                    <div className="flex items-center gap-2">
                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg" alt="" className="mb-1"/>
                                        <p className="text-[#4F4F42] text-base font-bold">{rfqData?.location}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:space-y-5 space-y-4">
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal">Status</p>
                                    <div className="flex items-center space-x-2">
                                        <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                                        <p className="text-[#4F4F42] text-base font-bold">{rfqData.status}</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-[#6B7280] text-base font-normal  ">Contact</p>
                                    <p className="text-[#4F4F42] text-base font-bold ">Vishal Rana (8826386281)</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Form >
                        <div className="xl:px-8 space-y-10 px-4">
                            {/* line items */}
                            <div className="py-6">

                                {/* Material Section */}
                                <p className="text-[32px] font-bold text-[#434343] hidden xl:block">Line Items</p>
                                <div className="mb-5 mt-2 hidden xl:grid xl:grid-cols-9 xl:gap-4 text-[#777777] text-base font-normal uppercase border-y py-2">
                                    <label className="block font-medium col-span-2">Description</label>
                                    <label className="block font-medium col-span-2">COMMENTS / METHODS</label>
                                    <label className="block font-medium col-span-1 text-end">BASE PRICE</label>
                                    <label className="block font-medium text-right col-span-1">Gst (%)</label>
                                    <label className="block font-medium text-right col-span-1 uppercase">Delivery Date</label>
                                    <label className="block font-medium text-center col-span-1 uppercase">Work Start Date</label>
                                    <label className="block font-medium text-center col-span-1">Total Amount</label>
                                </div>

                                <p className="text-base font-medium text-[#434343] hidden xl:block ">Your bid value is the sum of these line items</p>

                                {/* providing and fixing */}
                                <div >
                                    <div className="flex items-center mb-[30px] mt-[20px]">
                                        <h3 className="whitespace-nowrap text-[#434343] hidden xl:block uppercase text-base font-medium">Providing & Fixing</h3>
                                        <hr className="flex-grow border-t hidden xl:block border-gray-300 ml-4" />
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-semibold text-[#3B3B3B] px-2 xl:hidden">Providing & Fixing Requirements</h3>
                                        <p className="text-base font-normal text-[#9D9D9D] px-2 xl:hidden pb-4">Add quotations for providing & fixing
                                            requirements below</p>
                                    </div>
                                    <div className="xl:grid xl:grid-cols-9 space-y-8 xl:space-y-0 xl:gap-4 text-[#373737]">
                                        <div className="bg-[#F6F6F4] max-h-[182px] col-span-2 py-4 px-6 w-full " >
                                            <p className="w-full border-none col-span-3 p-0 bg-[#F6F6F4] text-base">
                                                Dismantling of RCC Slab and beams from 1st level inside the building using diamond cutting for isolation at periphery
                                            </p>
                                            <button
                                                onClick={togglePopup}
                                                type="button"
                                                className="text-[#8E8E85] text-sm italic underline pt-2"
                                            >
                                                Read More
                                            </button>

                                            {/* Popup Modal */}
                                            {isPopupOpen && (
                                                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                    <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                        <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                  scrollbar-width: none; /* Firefox */
                                                   }
                                                 .hide-scrollbar::-webkit-scrollbar {
                                                 display: none; /* Safari and Chrome */
                                                  }
                                                `}
                                                        </style>
                                                        <div className="sticky top-0 bg-white pb-4">
                                                            {/* Close Button */}
                                                            <button
                                                                onClick={togglePopup}
                                                                className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                            >
                                                                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>
                                                            <h2 className="text-lg font-semibold mb-4">Payment Details</h2>
                                                            <hr />
                                                        </div>
                                                        <p className="text-base text-[#3D3D3D] overflow-y-auto ">
                                                            Dismantling of RCC Slab and beams from 1st level inside the building using diamond cutting for isolation at periphery and breaking by using Hydraulic Splitter along with electric breaker not above 11 Kg. This process includes the removal of reinforced concrete structures with precision diamond cutting tools to ensure minimal damage to surrounding areas. The hydraulic splitter helps in breaking the concrete efficiently, while the electric breaker provides additional force for tougher sections. The work will be conducted following all safety standards and guidelines to ensure a safe and effective dismantling process.Please let us know if you have any questions or require further information regarding the scope of work. We are committed to delivering high-quality results and ensuring your complete satisfaction with the project.
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-span-2 px-4 xl:px-0">
                                            <label className="text-xl font-semibold mb-2 flex items-center xl:hidden relative">
                                                Comments/Methods
                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group">
                                                    i
                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                    </span>
                                                </span>
                                            </label>
                                            <Field
                                                as="textarea"
                                                name="comments"
                                                className="h-full w-[262px] p-2 resize-none border whitespace-pre-line border-gray-300 xl:w-full placeholder-[#373737]"
                                                placeholder=""
                                            // rows="4"
                                            />
                                            <ErrorMessage name="comments" component="div" className="text-red-500 text-sm mt-1" />
                                        </div>

                                        <div className="col-span-1 self-end px-4 xl:px-0">
                                            <label className="text-xl font-semibold mb-2 flex items-center xl:hidden relative">
                                                Base Price
                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group">
                                                    i
                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                    </span>
                                                </span>
                                            </label>
                                            <Field
                                                type="text"
                                                name="basePrice"
                                                className="p-2 xl:w-full w-[262px] h-[44px] border border-[#8E8E85] placeholder-[#373737]"
                                                // placeholder="Rs. 0"
                                                onChange={(e) => handleBasePriceChange(e, setFieldValue, values, setTotalAmount)}
                                            />
                                            <ErrorMessage name="basePrice" component="div" className="text-red-500 text-sm mt-1" />
                                        </div>
                                        {/* <div className="col-span-1">
                                                  <Field
                                                   type="text"
                                                   name="gst"
                                                   className="p-2 w-full h-[44px] border border-gray-300 placeholder-[#373737]"
                                                   onChange={(e) => handleGstChange(e, setFieldValue, values, setTotalAmount)}
                                                   />
                                                   <ErrorMessage name="gst" component="div" className="text-red-500 text-sm mt-1" />
                                          </div> */}
                                        {/* gst */}
                                        <div className="col-span-1 xl:ml-auto self-end h-fit xl:w-[64px] w-[262px] px-4 xl:px-0">
                                            <label htmlFor="" className="text-xl pb-2 font-semibold xl:hidden">GST (%)</label>
                                            <CustomGstDropdown
                                                options={[0, 5, 12, 18, 28]}
                                                // Display the current value
                                                // value={values.lineItems[index].gst}
                                                // onChange={(value) => handleFieldChange(index, 'gst', value, values)}
                                                className="xl:w-full w-[262px] "
                                            />
                                            {/* <ErrorMessage name={`lineItems[${index}].gst`} component="div" className="text-red-500 text-sm h-fit" /> */}
                                        </div>

                                        <div className="self-end px-4 xl:px-0 relative">
                                            <label className="text-xl font-semibold mb-2 flex items-center xl:hidden relative">
                                                Delivery Date
                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group">
                                                    i
                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                    </span>
                                                </span>
                                            </label>
                                            <Field
                                                type="date"
                                                name="deliveryDate"
                                                className="p-2 xl:w-full w-[262px] h-[44px] border border-gray-300 placeholder-[#373737]"
                                            />
                                            <div className="absolute mt-1 text-red-500 text-sm">
                                                <ErrorMessage name="deliveryDate" component="div" />
                                            </div>
                                        </div>


                                        <div className="self-end px-4 xl:px-0 relative">
                                            <label className="text-xl font-semibold mb-2 flex items-center relative xl:hidden">
                                                Work Start Date
                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group">
                                                    i
                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                    </span>
                                                </span>
                                            </label>
                                            <Field
                                                type="date"
                                                name="workStartDate"
                                                className="p-2 xl:w-full w-[262px] h-[44px] border border-gray-300 placeholder-[#373737]"
                                            />
                                            <div className="absolute mt-1 text-red-500 text-sm">
                                                <ErrorMessage name="workStartDate" component="div" className="text-red-500 text-sm mt-1" />
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 px-4 xl:py-0 xl:px-0 flex justify-between xl:justify-end items-center w-full self-end xl:bg-white bg-[#E7EAEF] border border-t-[#004491] xl:border-none">
                                            <p className="text-[#004491] font-bold text-xl xl:hidden">Total Amount</p>
                                            <div className="p-2 h-[44px] font-bold xl:font-normal text-right xl:text-black text-[#004491]">
                                                Rs.{totalAmount.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* miscellaneous */}
                            <div>
                                <h3 className="text-[32px] font-semibold text-[#434343] hidden xl:block mb-3">Miscellaneous</h3>

                                <h3 className="text-xl font-medium text-[#3B3B3B] px-2 xl:hidden">Miscellaneous Charges</h3>
                                <p className="text-base font-normal text-[#9D9D9D] px-2 pb-4 xl:hidden">Please indicate any miscellaneous charges that will be incurred</p>

                                <div className="bg-white border py-4 shadow-lg mx-2 xl:border-none xl:mx-0 xl:shadow-none hidden xl:block xl:py-0">
                                    <div className=" hidden xl:grid xl:grid-cols-6 px-4 items-center gap-2 text-[#777777] text-base font-normal uppercase border-y py-2">
                                        <label className="block font-medium col-span-3">Product</label>
                                        <label className="font-medium text-center mx-auto self-center flex justify-end items-center relative">
                                            MRP
                                            <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-2 pt-1 rounded-full lowercase relative group">
                                                i
                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-0 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                    Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                </span>
                                            </span>
                                        </label>
                                        <label className="block font-medium text-center col-span-1">GST (%)</label>
                                        <label className="block font-medium text-right col-span-1">Total Cost</label>
                                    </div>
                                </div>
                                <div className="border shadow-md xl:border-none px-3 pb-3 pt-4 space-y-6 xl:space-y-2">
                                    {charges.map((item, index) => (
                                        <div className="xl:grid xl:grid-cols-6 xl:gap-4 ">
                                            <React.Fragment key={index}>
                                                <p className="col-span-3 text-base font-semibold pb-2 xl:pb-0">{item.label}</p>
                                                {/* mrp and gst for mobile */}
                                                <div className="flex justify-between items-center xl:hidden pb-4">
                                                    <div className="col-span-1 flex justify-center">
                                                        <Field
                                                            type="text"
                                                            className="h-[44px] w-[120px]  border border-gray-300"
                                                            value={item.field1} // You can bind the field values here
                                                        />
                                                    </div>
                                                    <CustomGstDropdown className="col-span-1 w-[64px]" />
                                                </div>
                                                <div className="col-span-1 xl:flex justify-center hidden">
                                                    <Field
                                                        type="text"
                                                        className="h-[44px] w-[120px] border border-gray-300"
                                                        value={item.field1} // You can bind the field values here
                                                    />
                                                </div>
                                                <div className=" hidden xl:flex justify-center">
                                                    <CustomGstDropdown className="col-span-1 self-center xl:w-[64px]" />
                                                </div>
                                                <div className="flex justify-between xl:justify-end items-center py-3 px-4 bg-[#E7EAEF] border border-t-[#004491] xl:border-none xl:bg-white">
                                                    <p className="text-[#004491] font-semibold xl:hidden">Sub Total </p>
                                                    <p className="text-[#004491] font-semibold xl:font-normal col-span-1 xl:text-black xl:text-right">
                                                        Rs. </p>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    ))}
                                    <div className="xl:pt-4">
                                        <hr className="hidden xl:block" />
                                        <div className="flex px-4 py-5 xl:p-0 justify-between items-center border xl:border-none border-t-[#007366] bg-[#E0F0EE] xl:bg-white">
                                            <p className="xl:font-bold text-[#007366] xl:text-black font-semibold">Total Amount</p>
                                            <p className="bg-[#E0F0EE] text-[#007366] font-semibold text-end xl:font-normal xl:text-black w-fit min-w-[164px] xl:px-4 py-2">Rs. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Supporting Documents Section */}
                            <div className="mb-8  xl:mb-[40px]">
                                {/* <div className="flex items-center mt-[60px] mb-[30px]">
                                    <h3 className="whitespace-nowrap text-[#434343] uppercase text-base font-medium">SUPPORTING DOCUMENTS </h3>
                                    <hr className="flex-grow border-t border-gray-300 ml-4" />
                                </div> */}
                                <h3 className="text-[32px] font-medium text-[#434343] hidden xl:block mb-3">Supporting Documents</h3>
                                <h3 className="text-xl font-semibold text-[#3B3B3B] px-4 pb-4 xl:hidden">Supporting Documents</h3>
                                {/* <p className="text-base font-normal text-[#9D9D9D] px-2 pb-4 xl:hidden">Below are the supporting documents uploaded by the buyer. Please review them.</p> */}
                                <div className="xl:grid xl:grid-cols-4 xl:gap-4 space-y-4 xl:space-y-0 xl:mt-4 mx-auto">
                                    {attachedDocument.map((doc, index) => (
                                        <div key={index} className="flex justify-between items-center mx-4">
                                            <div className="flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-2 space-x-2 text-[#434343]">
                                                <img src={doc.imgSrc} alt="doc" />
                                                <label>{doc.label}</label>
                                            </div>
                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/download_solid.svg" alt="" className="xl:hidden" />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Additional Information Section */}
                            <div className="mb-8">
                                {/* <div className="flex items-center mt-[60px] mb-[30px]">
                                    <h3 className="whitespace-nowrap text-[#434343] uppercase text-base font-medium">ADDITIONAL INFORMATION</h3>
                                    <hr className="flex-grow border-t border-gray-300 ml-4" />
                                </div> */}
                                <h3 className="text-[32px] font-medium text-[#434343] hidden xl:block mb-3">Additional Information</h3>

                                <div className="hidden xl:flex justify-between items-center  mb-8">
                                    <p className="mb-2">
                                        Below are our payment terms. Do you agree?
                                    </p>
                                    <div className="flex space-x-8 mb-4">
                                        <label className="inline-flex items-center">
                                            <Field
                                                type="radio"
                                                name="paymentTerms"
                                                value="true"
                                                checked={values.paymentTerms === true} // Controlled check status
                                                onChange={() => {
                                                    setFieldValue('paymentTerms', true);
                                                    setIsCounterTerm(false); // Update counter term
                                                }}
                                                className="form-radio"
                                            />
                                            <span className="ml-2">Yes</span>
                                        </label>

                                        <label className="inline-flex items-center">
                                            <Field
                                                type="radio"
                                                name="paymentTerms"
                                                value="false"
                                                checked={values.paymentTerms === false} // Controlled check status
                                                onChange={() => {
                                                    setFieldValue('paymentTerms', false);
                                                    setIsCounterTerm(true); // Update counter term
                                                }}
                                                className="form-radio"
                                            />
                                            <span className="ml-2">Counter Offer</span>
                                        </label>
                                    </div>
                                    <ErrorMessage name="paymentTerms" component="div" className="text-red-500 text-sm" />

                                </div>

                                <h3 className="text-xl font-semibold text-[#3B3B3B] px-4 xl:hidden">Additional Information</h3>
                                <p className="text-base font-normal px-4 py-4 xl:hidden">Below are our payment Terms. </p>

                                <div className="xl:flex justify-between items-center">
                                    <div>
                                        <p className="font-semibold text-[#4F4F42] pb-2 hidden xl:block">Payment Terms</p>

                                        <div className="bg-[#F6F6F4] py-4 px-4 xl:px-6 xl:w-[390px] xl:h-[182px] xl:mx-0 mx-auto" >
                                            <p className="w-full border-none p-0 bg-[#F6F6F4] text-[#3D3D3D] h-[120px] text-base">
                                                Dismantling of RCC Slab and beams from 1st level inside the building using diamond cutting for isolation at periphery and breaking by using Hydraulic Splitter along with electric breaker not above 11 Kg
                                            </p>
                                            <button
                                                onClick={togglePopup}
                                                type="button"
                                                className="text-[#8E8E85] italic text-sm underline pt-2"
                                            >
                                                Read More
                                            </button>

                                            {/* Popup Modal */}
                                            {isPopupOpen && (
                                                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                    <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                        <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                  scrollbar-width: none; /* Firefox */
                                                   }
                                                 .hide-scrollbar::-webkit-scrollbar {
                                                 display: none; /* Safari and Chrome */
                                                  }
                                                `}
                                                        </style>
                                                        <div className="sticky top-0 bg-white pb-4">
                                                            {/* Close Button */}
                                                            <button
                                                                onClick={togglePopup}
                                                                className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                            >
                                                                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>
                                                            <h2 className="text-lg font-semibold mb-4">Payment Details</h2>
                                                            <hr />
                                                        </div>
                                                        <p className="text-base text-[#3D3D3D] overflow-y-auto ">
                                                            Dismantling of RCC Slab and beams from 1st level inside the building using diamond cutting for isolation at periphery and breaking by using Hydraulic Splitter along with electric breaker not above 11 Kg. This process includes the removal of reinforced concrete structures with precision diamond cutting tools to ensure minimal damage to surrounding areas. The hydraulic splitter helps in breaking the concrete efficiently, while the electric breaker provides additional force for tougher sections. The work will be conducted following all safety standards and guidelines to ensure a safe and effective dismantling process.Please let us know if you have any questions or require further information regarding the scope of work. We are committed to delivering high-quality results and ensuring your complete satisfaction with the project.
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="xl:hidden justify-between items-center p-4">
                                        <p >
                                            Do you agree ?
                                        </p>
                                        <div className="flex space-x-12 my-4">
                                            <label className="inline-flex items-center">
                                                <input type="radio" name="paymentTerms" className="form-radio" />
                                                <span className="ml-2">Yes</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input type="radio" name="paymentTerms" className="form-radio" />
                                                <span className="ml-2">Counter Offer</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" mx-4 xl:mx-0">
                                        <p className="font-semibold text-[#4F4F42]">Counter Payment Terms</p>
                                        {/* <p className="xl:hidden text-sm text-[#9D9D9D]">If you do not agree then please provide the buyer with counter payment terms</p> */}
                                        <Field
                                            as="textarea"
                                            name="counterPaymentTerms"
                                            className="xl:w-[390px] w-full h-[144px] my-4 xl:mt-2 border border-gray-300 placeholder:italic"
                                            placeholder="Please write your counter payment terms Rich Text "
                                        />
                                    </div>
                                    {/* <div className=" xl:flex mt-8">
                                        <p className="text-[#434343]">Notes</p>
                                        <Field
                                            as="textarea"
                                            name="notes"
                                            placeholder="Add Notes (Optional) "
                                            className="border-[#CDCDB8] xl:ml-5 w-full h-[125px] placeholder:italic placeholder-[#8C8C8C]"
                                        />
                                    </div> */}
                                </div>
                                <div className=" xl:flex mt-4 xl:mt-8  mx-4 xl:mx-0">
                                    <p className="text-[#434343] mb-2 xl:mb-0">Notes</p>
                                    <Field
                                        as="textarea"
                                        name="notes"
                                        placeholder="Add Notes (Optional) "
                                        className="border-[#CDCDB8] w-full xl:ml-5 xl:w-full h-[125px] placeholder:italic placeholder-[#8C8C8C]"
                                    />
                                </div>
                            </div>

                            {/* Upload Supporting Documents */}
                            <div className="pb-12 mx-4 xl:mx-0">
                                <h3 className="whitespace-nowrap text-[#434343] text-xl font-bold xl:mt-[60px] mb-4 xl:mb-[30px]">Upload Supporting Documents</h3>
                                {/* <p className="text-base font-normal text-[#9D9D9D] px-4 xl:px-0 pt-2 pb-8 xl:hidden">Please upload any supporting documents for this requirement, that you may want the buyer to review for considering this quotation</p> */}

                                {attachmentsSkeleton.length > 0 && (
                                    <p className="text-xs pl-3">
                                        Your file is being scanned for upload, be patient and continue to fill
                                        the rest of the form.
                                    </p>
                                )}

                                <div className="px-3">
                                    {/* Display the selected file names */}
                                    <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                        {selectedFilesBase.map((file, index) => (
                                            <a
                                                href={file.Content}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                key={file.Name}
                                            >
                                                <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                    {file.Name}
                                                </span>
                                                <span
                                                    className="ml-1 cursor-pointer"
                                                    onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 48 48"
                                                    >
                                                        <circle cx="24" cy="24" r="22" fill="gray" />
                                                        <line
                                                            x1="15"
                                                            y1="15"
                                                            x2="33"
                                                            y2="33"
                                                            stroke="white"
                                                            strokeWidth="2"
                                                        />
                                                        <line
                                                            x1="33"
                                                            y1="15"
                                                            x2="15"
                                                            y2="33"
                                                            stroke="white"
                                                            strokeWidth="2"
                                                        />
                                                    </svg>
                                                </span>
                                            </a>
                                        ))}

                                        {/* Skeleton loaders for files being processed */}
                                        {attachmentsSkeleton.map((_, index) => (
                                            <Skeleton
                                                key={index}
                                                variant="rounded"
                                                sx={{ borderRadius: "20px", margin: "4px" }}
                                                width={150}
                                                height={32}
                                            />
                                        ))}
                                    </ul>
                                </div>

                                <div>
                                    {attachmentsUploadsErrors && (
                                        <div className="mt-3">
                                            <p className="text-red-500 font-semibold text-base mt-14">
                                                {attachmentsUploadsErrors}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                {/* Error handling for file size */}
                                <div
                                    className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                        }`}
                                >
                                    <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                        <p>Total size of attachments is not more than 10 MB</p>
                                        <button
                                            onClick={handleCloseFileSizeError}
                                            className="px-4 py-2 focus:outline-none"
                                        >
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>


                                {/* drag and drop file */}
                                <div className="xl:ml-14 xl:mr-0 flex items-center justify-center h-[175px] xl:h-[301px] bg-[#F1F7FF] xl:bg-white border-2 border-dashed border-[#004491] xl:border-[#7D7D7D] relative">
                                    <div className="xl:hidden flex flex-col items-center justify-center">
                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" alt="" className="mx-auto" />
                                        <p className="text-center mt-4">Upload your files here</p>
                                        <label htmlFor="file" className="text-[#1D28FA] underline text-center cursor-pointer">
                                            Browse
                                        </label>
                                    </div>
                                    <input
                                        type="file"
                                        accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                        onClick={handleFileUploadClick}
                                        onChange={handleFileUpload}
                                        multiple // Allow multiple files to be selected
                                    />
                                    <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500 pointer-events-none">
                                        <div className="relative mx-auto hidden xl:block">
                                            {/* Overlapping rectangles */}
                                            <div className="absolute top-6 -left-10 h-[100px] w-[75px] bg-white border border-[#878787]"></div>
                                            <div className="absolute -top-6 left-0 h-[100px] w-[75px] bg-white border border-[#878787]"></div>
                                        </div>
                                        {/* Centered text */}
                                        <p className="mt-36 text-center hidden xl:block">Drag & Drop or Click to upload your proposal instead</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }} className="sticky bottom-0 bg-white flex justify-end items-center py-6 px-4 xl:px-8">
                            <button type="button" className=" w-[160px] text-[#434343] font-bold" onClick={handleCloseForm}>
                                Cancel
                            </button>
                            <button type="submit" className="bg-[#5188F4] text-white w-[160px] h-[51px] flex justify-center items-center">
                                Submit Quotation
                            </button>
                        </div>

                    </Form>
                </div >
            )}
        </Formik >
    );
};

export default ProvidingAndFixing;
