import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, useFormikContext } from 'formik';
import ReactQuill from "react-quill";
import * as XLSX from 'xlsx';
import StaticPopup from "components/StaticPopup";
import { UNITOPTIONS } from 'config'
const downloatTemplate = "https://storagereponeevaydevcdn.blob.core.windows.net/business/material_BulkUpload_Template.xlsx"

const AddIndividual = () => {

    const { values, setFieldValue, errors, validateForm, setTouched } = useFormikContext();

    const initialMaterials = values.materials

    // console.log("Initial materials", initialMaterials)

    const [materials, setMaterials] = useState(initialMaterials);
    const [uploadedMaterials, setUploadedMaterials] = useState([])
    const [showErrors, setShowErrors] = useState(false);
    const [isClose, setIsClosed] = useState(true);
    const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);
    const [activeUnitIndex, setActiveUnitIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState(""); // New state for the search term
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [fileError, setFileError] = useState('');
    const [file, setFile] = useState(null);
    const dropdownRef = useRef(null); // Ref for dropdown wrapper

    const handleBulkUpload = () => {
        setOpenUploadFile(!openUploadFile)
    }

    const isMaterialEmpty = (material) => {
        return !material.name && !material.quantity && !material.unit && material.brands.length === 0 && !material.specifications;
    };

    const cleanUpMaterials = (materials) => {
        const filteredMaterials = materials.filter((material) => !isMaterialEmpty(material));
        if (filteredMaterials.length === 0) {
            return [{
                name: '',
                quantity: '',
                unit: '',
                brands: [],
                specifications: ''
            }];
        }
        return filteredMaterials;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActiveUnitIndex(null); // Close dropdown
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const filteredUnitOptions = UNITOPTIONS.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputChange = (index, field, value) => {
        const updatedMaterials = materials.map((material, i) =>
            i === index ? { ...material, [field]: value } : material
        );
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials)

        // Use a timeout to batch Formik updates and avoid multiple rapid calls
        // setTimeout(() => , 0);
    };

    const handleBrandInputChange = (index, value) => {
        const updatedMaterials = [...materials];
        const brandsArray = value.split(",").map((brand) => brand.trim()); // Split input by comma and trim spaces
        updatedMaterials[index].brands = brandsArray; // Update the brands array
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials);
    };

    const removeRow = (index) => {
        if (materials.length > 1) {
            const updatedMaterials = materials.filter((_, i) => i !== index);
            setMaterials(updatedMaterials);
            setFieldValue('materials', updatedMaterials); // Sync with Formik
        }
    };

    const addNewRow = () => {
        const newMaterial = {
            name: "",
            quantity: "",
            unit: "",
            brands: [],
            specifications: "",
        };
        const updatedMaterials = [...materials, newMaterial];
        setMaterials(updatedMaterials);
        setFieldValue('materials', updatedMaterials); // Sync with Formik
    };


    const handleValidation = async () => {
        setShowErrors(true);
        await validateForm(); // Trigger Formik's validation
        const cleanedMaterials = cleanUpMaterials(materials);
        setMaterials(cleanedMaterials);
        setFieldValue('materials', cleanedMaterials); // Sync cleaned materials with Formik
    };

    const mapSheetDataToMaterials = (sheetData, unitMapping) => {
        // Skip the header row and map data rows to material structure
        return sheetData.slice(1).map((row) => {
            const unitCustomName = row[4]?.toLowerCase().trim(); // `Unit_Code` is now at index 4
            const standardUnit = unitMapping[unitCustomName] || unitCustomName; // Map to standard unit or fallback

            // Validate required fields: `name`, `quantity`, and `unit_code`
            if (!row[0] || !row[3] || !row[4]) {
                throw new Error(
                    `Row ${sheetData.indexOf(row) + 1}: Missing required fields (Name, Quantity, Unit_Code)`
                );
            }

            return {
                name: row[0] || "", // Name column
                brands: row[1] ? row[1].split(",").map((brand) => brand.trim()) : [], // Brands column
                specifications: row[2] || "", // Specifications column
                quantity: row[3] || "", // Quantity column
                unit: standardUnit, // Standardized unit
            };
        });
    };



    const handleFileUploadMaterial = (e) => {
        const file = e.target.files[0];
        setFile(file); // Set the file in state
        if (file) {
            console.log("File selected for materials upload:", file); // Log file info
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // Parse the Instructions Sheet
                const instructionsSheet = workbook.Sheets["Instructions (Must Read)"];
                if (!instructionsSheet) {
                    setFileError("Instructions sheet is missing.");
                    return;
                }

                const instructionsData = XLSX.utils.sheet_to_json(instructionsSheet, {
                    header: 1,
                    range: "A17:B26", // Only parse rows 17–26
                });

                // Create a mapping from standard units to custom names
                const unitMapping = {};
                instructionsData.forEach((row) => {
                    if (row[0] && row[1]) {
                        unitMapping[row[1].toLowerCase()] = row[0];
                    }
                });

                // Parse the Materials Data Sheet
                const materialsSheet = workbook.Sheets["Material LIst"];
                if (!materialsSheet) {
                    setFileError("Materials Data sheet is missing.");
                    return;
                }

                const materialsData = XLSX.utils.sheet_to_json(materialsSheet, {
                    header: 1, // Include headers
                });


                // Validate Materials Data
                const requiredHeaders = ["name", "quantity", "unit_code"];
                const fileHeaders = materialsData[0]?.map((header) => header.toLowerCase().trim());
                const hasValidHeaders = requiredHeaders.every((header) => fileHeaders.includes(header));

                if (!hasValidHeaders) {
                    setFileError("Invalid file format. Please ensure headers include Name, Quantity, and Unit_Code.");
                    return;
                }

                // Check if there are no data rows after the header
                if (materialsData.length <= 1) {
                    setFileError("The uploaded sheet contains no data rows. Please provide material details.");
                    return;
                }

                const missingFields = materialsData.slice(1).filter((row) => {
                    const rowObj = {
                        name: row[fileHeaders.indexOf("name")],
                        quantity: row[fileHeaders.indexOf("quantity")],
                        unit_code: row[fileHeaders.indexOf("unit_code")],
                    };
                    return !rowObj.name || !rowObj.quantity || !rowObj.unit_code;
                });

                if (missingFields.length > 0) {
                    setFileError(`Error: Some rows are missing required fields (Name, Quantity, Unit_Code).`);
                    console.error("Invalid rows:", missingFields);
                    return;
                }

                // Convert and Save Materials Data
                const uploadedMaterials = mapSheetDataToMaterials(materialsData, unitMapping);
                console.log("Final Materials Data with Converted Units:", materials);

                const updatedMaterials = cleanUpMaterials([...materials, ...uploadedMaterials]);
                // setMaterials(updatedMaterials);
                setTimeout(() => setFieldValue('materials', updatedMaterials), 0);
                setFileError(""); // Clear errors
                setOpenUploadFile(false);
            };

            reader.readAsArrayBuffer(file); // Trigger file reading
        }
    };

    useEffect(() => {
        if (JSON.stringify(materials) !== JSON.stringify(values.materials)) {
            setMaterials(values.materials);
        }
    }, [values.materials, materials]);

    return (
        <>

            <div className="relative flex my-2">

                {values?.requirementType === "contractor" ? (
                    <p className="inline-flex items-center">
                        Material in Scope
                        <span className="ml-2 relative group bg-gray-200 rounded-full flex items-center justify-center w-4 h-4 text-[10px] cursor-pointer group-hover:text-gray-600">
                            i
                            <div className="absolute bottom-4 mt-1 hidden w-48 shadow-md border p-2 text-xs text-black bg-white rounded group-hover:block">
                                If there are materials, materials need to be provided.
                            </div>
                        </span>
                    </p>
                ) : (
                    <div className="w-full justify-start items-center self-end text-sm font-medium gap-5 ">
                        Material List
                    </div>
                )}

                <button
                    type="button"
                    className="hover:bg-gray-100 border-gray-300 rounded-sm text-sm flex items-center justify-center text-left whitespace-nowrap ml-auto"
                    onClick={handleBulkUpload}
                >
                    <img
                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_bulk_upload.svg"
                        alt=""
                        className="pr-2"
                    />
                    Bulk Upload
                </button>
            </div>

            <div className="flex items-center justify-center w-full">
                {isClose && (
                    <div className="bg-gray-500 bg-opacity-50">
                        <div className=" border border-gray-300 bg-white w-full ">
                            {/* Header for the grid layout */}
                            <div className="  bg-gray-100 grid grid-cols-22 gap-y-1 gap-x-2 text-sm text-[#777777] px-2 pt-3 pb-2">
                                <p className="text-center">Sr#</p>
                                <p className="col-span-3">Material Name</p>
                                <p className="col-span-1">Qty</p>
                                <p className="col-span-2">Unit</p>
                                <p className="col-span-5">Material Brands (comma separated)</p>
                                <p className="col-span-9 text-center">Specifications</p>
                                <p className="col-span-1"></p> {/* Empty for the delete icon */}
                            </div>

                            <div className="p-2 text-sm ">
                                {materials?.map((material, index) => (
                                    <div key={index} className="grid grid-cols-22  gap-x-1 mb-2">
                                        <p className="text-center">{index + 1}</p>

                                        <div className="col-span-3">
                                            <ReactQuill
                                                value={material?.name || ''}
                                                // fieldName={`materials.${index}.name`}
                                                onChange={(value) => handleInputChange(index, "name", value)}
                                                theme="snow"
                                                className="min-h-[28px] max-h-[60px] p-0 word-break whitespace-normal focus:outline-none focus:ring-0 text-sm w-full overflow-y-auto remove-quill-padding"
                                                modules={{ toolbar: false }}
                                            />
                                            <ErrorMessage
                                                name={`materials.${index}.name`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />
                                        </div>

                                        <div className="col-span-1 self-start flex flex-col justify-end">
                                            <input
                                                type="text"
                                                name={`materials.${index}.quantity`}
                                                value={material.quantity}
                                                onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                                className="border border-[#DCDCD0] focus:outline-none focus:ring-0 p-1 h-[28px] w-full text-sm"
                                            />
                                            <ErrorMessage
                                                name={`materials.${index}.quantity`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />
                                        </div>

                                        <div
                                            className="relative self-start col-span-2 flex flex-col justify-end text-xs"
                                            ref={dropdownRef}
                                        >
                                            <input
                                                type="text"
                                                name={`materials.${index}.unit`}
                                                value={material.unit}
                                                onChange={(e) => {
                                                    handleInputChange(index, "unit", e.target.value);
                                                    setSearchTerm(e.target.value);
                                                }}
                                                onFocus={() => setActiveUnitIndex(index)}
                                                onBlur={(e) => {
                                                    if (!dropdownRef.current.contains(e.relatedTarget)) {
                                                        setActiveUnitIndex(null);
                                                    }
                                                }}
                                                className="border border-[#DCDCD0] focus:outline-none focus:ring-0 p-2 h-[28px] w-full text-xs font-normal"
                                            />
                                            <ErrorMessage
                                                name={`materials.${index}.unit`}
                                                component="div"
                                                className="text-red-500 text-xs mt-1"
                                            />

                                            {activeUnitIndex === index && (
                                                <ul
                                                    className="absolute z-20 w-full font-normal bg-white border max-h-[100px] overflow-y-auto mt-1 shadow-lg"
                                                    style={{ top: "100%", left: 0 }}
                                                >
                                                    {filteredUnitOptions.length > 0 ? (
                                                        filteredUnitOptions.map((option, i) => (
                                                            <li
                                                                key={i}
                                                                tabIndex={-1}
                                                                onMouseDown={(e) => {
                                                                    e.preventDefault();
                                                                    handleInputChange(index, "unit", option);
                                                                    setSearchTerm("");
                                                                    setActiveUnitIndex(null);
                                                                }}
                                                                className="p-2 cursor-pointer hover:bg-gray-100 text-xs"
                                                            >
                                                                {option}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li className="p-2 text-sm text-gray-500">No matching results</li>
                                                    )}
                                                </ul>
                                            )}
                                        </div>

                                        <div className="col-span-5 self-start flex flex-col justify-end">
                                            <input
                                                type="text"
                                                value={materials[index].brands.join(", ") || ''}
                                                onChange={(e) => handleBrandInputChange(index, e.target.value)}
                                                className="border border-[#DCDCD0] p-2 h-[28px] w-full text-sm focus:outline-none focus:ring-0"
                                            />
                                        </div>

                                        <div className="col-span-9">
                                            <ReactQuill
                                                value={material?.specifications}
                                                onChange={(value) => handleInputChange(index, "specifications", value)}
                                                theme="snow"
                                                className="min-h-[30px] max-h-[60px] p-0 word-break whitespace-normal focus:outline-none focus:ring-0 text-sm w-full overflow-y-auto remove-quill-padding"
                                                modules={{ toolbar: false }}
                                            />
                                        </div>

                                        <div className="col-span-1 flex items-center justify-evenly">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="w-fit text-lg"
                                                    onClick={addNewRow}
                                                >
                                                    {/* Plus Icon (Add) */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v14m7-7H5" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <div>
                                                {materials.length > 1 && (
                                                    <button
                                                        type="button"
                                                        onClick={() => removeRow(index)}
                                                        className="text-lg"
                                                    >
                                                        {/* Minus Icon (Remove) */}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" >
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                                                        </svg>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <StaticPopup
                openModal={openUploadFile}
                handleClose={() => setOpenUploadFile(false)}
                size={"sm"}
                width={"xl"}
                bodyStyle="pb-0 pt-0"
                className="px-0"
                header={
                    <div className='text-base'>
                        Upload Files
                    </div>
                }>

                <div className="flex flex-col justify-center items-center px-0">
                    <div className="w-[400px] h-[180px] my-4 flex flex-col justify-center items-center border-2 border-dashed border-[#004491] bg-[#F1F7FF]">
                        <input
                            id="file-upload-material"
                            type="file"
                            className="hidden"
                            onChange={handleFileUploadMaterial}
                            accept=".xlsx, .xls"
                        />
                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" className='w-8 h-8 mb-2' />
                        <p className="text-sm whitespace-nowrap">
                            Drag & Drop or
                            <label htmlFor="file-upload-material" className="underline px-2 text-[#1D28FA] cursor-pointer">
                                Choose File
                            </label>
                            to upload
                        </p>
                    </div>

                    {fileError && <div className="text-red-500 text-sm">{fileError}</div>}

                    <div>
                        <p className='text-sm text-center w-full font-normal text-[#777777]'>Don't have the template? </p>
                        <div className='flex items-center justify-center gap-2'>
                            <a href={downloatTemplate} download className='text-sm text-center text-[#1D28FA] font-normal'>
                                Download Here
                            </a>
                            <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.5625 1.5625C9.5625 0.974805 9.0877 0.5 8.5 0.5C7.9123 0.5 7.4375 0.974805 7.4375 1.5625V9.6209L5.00039 7.18379C4.58535 6.76875 3.91133 6.76875 3.49629 7.18379C3.08125 7.59883 3.08125 8.27285 3.49629 8.68789L7.74629 12.9379C8.16133 13.3529 8.83535 13.3529 9.25039 12.9379L13.5004 8.68789C13.9154 8.27285 13.9154 7.59883 13.5004 7.18379C13.0854 6.76875 12.4113 6.76875 11.9963 7.18379L9.5625 9.6209V1.5625ZM2.125 12.1875C0.95293 12.1875 0 13.1404 0 14.3125V15.375C0 16.5471 0.95293 17.5 2.125 17.5H14.875C16.0471 17.5 17 16.5471 17 15.375V14.3125C17 13.1404 16.0471 12.1875 14.875 12.1875H11.5049L10.0008 13.6916C9.1707 14.5217 7.82598 14.5217 6.9959 13.6916L5.49512 12.1875H2.125ZM14.3438 14.0469C14.5551 14.0469 14.7578 14.1308 14.9072 14.2803C15.0567 14.4297 15.1406 14.6324 15.1406 14.8438C15.1406 15.0551 15.0567 15.2578 14.9072 15.4072C14.7578 15.5567 14.5551 15.6406 14.3438 15.6406C14.1324 15.6406 13.9297 15.5567 13.7803 15.4072C13.6308 15.2578 13.5469 15.0551 13.5469 14.8438C13.5469 14.6324 13.6308 14.4297 13.7803 14.2803C13.9297 14.1308 14.1324 14.0469 14.3438 14.0469Z"
                                    fill="blue"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className='flex justify-end px-0' style={{
                    boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)', // Adjust the shadow as needed
                }}>
                    <button className='w-[95px] h-[35px] text-sm mx-4 my-4 bg-black text-white' onClick={() => setOpenUploadFile(false)}>
                        Upload
                    </button>
                </div>
            </StaticPopup>
        </>
    );
};

export default AddIndividual;
