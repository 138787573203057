import { useEffect } from 'react';

const RFQTypeDropDown = ({ showDropdown, setShowDropdown, handleRequirementTypeSelect }) => {
  
  // Close the dropdown if clicked outside
  useEffect(() => {
    // Function to detect clicks outside the dropdown
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-container');
      if (dropdown && !dropdown.contains(event.target)) {
        setShowDropdown(false); // Close the dropdown when clicked outside
      }
    };

    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropdown]);

  return (
    <div>
      {/* Overlay background when dropdown is open */}
      {showDropdown && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10" />
      )}

      {showDropdown && (
        <div className="z-20 absolute top-[90px] right-5 mt-2 w-100 bg-white shadow-lg dropdown-container rounded-sm">
          {/* Dropdown Header */}
          <div className="px-4 py-2 border-b border-gray-300">
            <strong className="text-sm">Please Select RFQ Type</strong>
          </div>

          {/* Dropdown Options */}
          <div>
            {[{
              value: "contractor",
              label: "Contractor With Material",
              description: "Select if this requirement is for Turnkey Contractor",
            },
            {
              value: "labour",
              label: "Labour/Item Rate",
              description: "Select if this requirement is for Labour or Item Rate",
            },
            {
              value: "material",
              label: "Material Only",
              description: "Select if this requirement is for Material",
            }].map((option) => (
              <div
                key={option.value}
                className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                onClick={() => handleRequirementTypeSelect(option.value)}
              >
                <strong className="block text-sm">{option.label}</strong>
                <p className="text-xs text-gray-500">{option.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


export default RFQTypeDropDown;