import { Box, FormHelperText, Grid, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
import { QuillEditor } from 'components'
import CustomToolbar from 'components/QuillCustomTollBar'
import React from 'react'


const useStyles = makeStyles(() => ({
    root: {},
    editor: {
        "& .ql-editor": {
            height: "120px",
        },
    },
}));

const DescriptionSection = ({ handleChange, values, errors, touched, setFieldValue, handleOnBlur,NonEntityUser }) => {

    const classes = useStyles();
    const modules = {
        toolbar: {
            container: "#toolbar",
        },
    }
    return (
        <>
            <Grid sx={{ marginTop:NonEntityUser? "40px":"24px" }}>

                <Typography sx={{ mb: "10px" }}>Product list/Scope of Work- </Typography>

                <Grid sx={{
                    border: "1px solid rgba(112, 112, 112, 1)",
                    borderRadius: "10px 10px 0px 0px",
                }}>
                    <QuillEditor
                        required
                        className={classes.editor}
                        value={values.description}
                        onChange={(value) => setFieldValue("description", value)}
                        onBlur={() => handleOnBlur("description", values.description)}
                        modules={modules}
                        placeholder={"Description......"}
                        error={Boolean(touched.description && errors.description)}
                        helperText={
                            touched.description && errors.description
                                ? "Please enter discription"
                                : ""
                        }
                    />
                    <CustomToolbar />

                </Grid>
                {touched.description && errors.description && (
                    <Box mt={"8px"}>
                        <FormHelperText error>{errors.description}</FormHelperText>
                    </Box>
                )}

            </Grid>
        </>
    )
}

export default DescriptionSection