import React, { useEffect, useState } from 'react';
import MoreOptions from './MoreOptions';
import MaterialRFQTable from './MaterialRFQTable';
import LabourRFQTable from './LabourRFQTable';
import ContractorRFQTable from './ContractorRFQTable';

const ComparisontableTesting = ({ rfqData, userProfile }) => {
    const [openNoteIndex, setOpenNoteIndex] = useState(null);
    const [openDropdown, setOpenDropdown] = useState({ rowIndex: null, vendorIndex: null });
    const [withGst, setWithGst] = useState(false); // GST state
    const [sortedSuppliers, setSortedSuppliers] = useState([]);
    // const [totalAmountHeader,setTotalAmountHeader] = useState("") 

    // if (rfqData?.rfqType === "material"){
    //    setTotalAmountHeader("Total (Material Amount+Extra Charges)")
    // }
    // else if (rfqData?.rfqType === "labour"){
    //     setTotalAmountHeader("Total (Labour Cost+Extra Charges)")

    // }

    const handleMaterialDropdown = (rowIndex, vendorIndex) => {
        if (openDropdown.rowIndex === rowIndex && openDropdown.vendorIndex === vendorIndex) {
            setOpenDropdown({ rowIndex: null, vendorIndex: null });
        } else {
            setOpenDropdown({ rowIndex, vendorIndex });
        }
    };

    const handleTransportationClicked = (index, e) => {
        e.stopPropagation();
        setOpenNoteIndex(openNoteIndex === index ? null : index);
    };

    const handleGstChange = (e) => {
        setWithGst(!withGst);
    };

    // const getInitials = (name) => {
    //     if (!name) return '';
    //     const names = name.split(' ');
    //     return names.map(name => name.charAt(0).toUpperCase()).join('');
    // };

    const getInitials = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        // Get the first four initials
        return names.slice(0, 4).map(name => name.charAt(0).toUpperCase()).join('');
    };


    useEffect(() => {
        // Filter and sort suppliers whenever rfqData or withGst changes
        const filteredAndSorted = rfqData.suppliers
            .filter(supplier => supplier?.quotationReceived)
            .sort((a, b) => {
                const rankA = withGst ? a.vendorQuotations?.rankWithGst : a.vendorQuotations?.rank;
                const rankB = withGst ? b.vendorQuotations?.rankWithGst : b.vendorQuotations?.rank;

                // Convert ranks like "L1", "L2" to numerical values for comparison
                const numA = parseInt(rankA.substring(1)); // Extract the number part (e.g., "1" from "L1")
                const numB = parseInt(rankB.substring(1));

                return numA - numB; // Ascending order
            });

        setSortedSuppliers(filteredAndSorted);
    }, [rfqData, withGst]);

    const getL1SupplierData = () => {
        const l1Supplier = sortedSuppliers[0];
        return l1Supplier ? l1Supplier.vendorQuotations?.quotationSets?.slice(-1)[0] : null;
    };

    const l1Data = getL1SupplierData();

    const calculateLowerCount = (supplier, l1Data) => {
        let lowerCount = 0;

        if (l1Data) {
            // Handle materials

            if (rfqData.rfqType === 'material') {
                const l1MaterialPrices = l1Data.materialDetails.reduce((acc, item) => {
                    acc[item.name] = item.basePrice;
                    return acc;
                }, {});

                supplier.vendorQuotations?.quotationSets?.slice(-1)[0]?.materialDetails.forEach(item => {
                    const l1Price = l1MaterialPrices[item.name];
                    const supplierPrice = item.basePrice;
                    if (l1Price && supplierPrice < l1Price) {
                        lowerCount++;
                    }
                });
            }

            // Handle labourItems if rfqType is 'labour'
            if (rfqData.rfqType === 'labour') {
                const l1LabourRates = l1Data.labourItems.reduce((acc, item) => {
                    acc[item.skill] = item.labourRate;
                    return acc;
                }, {});

                supplier.vendorQuotations?.quotationSets?.slice(-1)[0]?.labourItems.forEach(item => {
                    const l1Rate = l1LabourRates[item.skill];
                    const supplierRate = item.labourRate;
                    if (l1Rate && supplierRate < l1Rate) {
                        lowerCount++;
                    }
                });
            }

            // Handle contractorDetails if rfqType is 'contractor'
            if (rfqData.rfqType === 'contractor') {
                const l1ContractorRates = l1Data.contractorDetails;

                const supplierContractorDetails = supplier.vendorQuotations?.quotationSets?.slice(-1)[0]?.contractorDetails;
                if (supplierContractorDetails) {
                    const supplierRate = supplierContractorDetails.baseRate;
                    const l1Rate = l1ContractorRates.baseRate;
                    if (l1Rate && supplierRate < l1Rate) {
                        lowerCount++;
                    }
                }
            }
        }

        return lowerCount;
    };


    return (
        <div className="relative overflow-x-auto" style={{ fontFamily: "goldman_sans", maxHeight: "calc(100vh - 70px)" }}>

            <table className="h-full">
                {/* header */}
                <thead className="sticky top-0 bg-white z-20">
                    <tr>
                        {/* fixed header */}
                        <th className="fixed-column z-10 border-0 sticky top-0 left-0 bg-white min-w-[300px] text-left" >
                            <div className='h-[20px] bg-[#343417]' style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}></div>
                            <div className='px-[22px] flex flex-col justify-start' style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                                <div>
                                    <h3 className="py-2 text-sm">Vendor Comparison</h3>
                                    {/* <div className="flex justify-start items-center pb-2 space-x-[22px]">
                                        <div className="flex items-center">
                                            <input
                                                id="with-gst"
                                                type="radio"
                                                value="with-gst"
                                                name="gst-radio"
                                                checked={withGst}
                                                onChange={handleGstChange}
                                                className="w-3 h-3 mb-1 bg-gray-100  checked:bg-black checked:border-black focus:ring-black focus:ring-1"
                                            />
                                            <label
                                                htmlFor="with-gst"
                                                className="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                with GST
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="without-gst"
                                                type="radio"
                                                value="without-gst"
                                                name="gst-radio"
                                                checked={!withGst}
                                                onChange={handleGstChange}
                                                className="w-3 h-3  mb-1 border-gray-300 checked:bg-black checked:border-black focus:ring-black focus:ring-1"
                                            />
                                            <label
                                                htmlFor="without-gst"
                                                className="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                without GST
                                            </label>
                                        </div>
                                    </div> */}
                                    <h3 className="text-xs pt-6 font-normal text-[#44441A]">
                                        {/* Estimated Cost: 4,90,000 {withGst ? 'w/ GST' : 'w/o GST'} */}
                                        Estimated Delivery: <span className=' text-xs'> {rfqData?.providingDate ? new Date(rfqData.providingDate).toLocaleDateString('en-GB') : ''}</span>
                                    </h3>
                                    <h3 className='text-xs pb-[10px] pt-1 '>Line Items</h3>
                                    <div className='bg-[#1E1E08] h-[2px] '></div>
                                </div>
                            </div>
                        </th>
                        {/* quotation header */}
                        {sortedSuppliers?.map((supplier, index) => {
                            // Calculate lower-priced items
                            // let lowerCount = 0;
                            const lowerCount = calculateLowerCount(supplier, l1Data);

                            // if (l1Data) {
                            //     const l1MaterialPrices = l1Data.materialDetails.reduce((acc, item) => {
                            //         acc[item.name] = item.basePrice; 
                            //         return acc;
                            //     }, {});

                            //     supplier.vendorQuotations?.quotationSets?.slice(-1)[0]?.materialDetails.forEach(item => {
                            //         const l1Price = l1MaterialPrices[item.name];
                            //         const supplierPrice = item.basePrice; 
                            //         if (l1Price && supplierPrice < l1Price) {
                            //             lowerCount++;
                            //         }
                            //     });
                            // }

                            return (
                                <th
                                    key={index}
                                    className={`min-w-[300px] z-0 sticky top-0 px-4 pt-[21px] bg-white border-b border-gray-300 ${index < sortedSuppliers?.length - 1 ? 'border-r' : ''}`}
                                >
                                    <div className='sticky top-0 bg-white'>
                                        <div className='flex justify-between'>
                                            <div className='flex justify-start space-x-4'>
                                                <div className="w-[36px] h-[36px] rounded-full bg-[#343417] flex items-center justify-center ">
                                                    <p className='text-sm font-normal text-white pt-1'>
                                                        {getInitials(supplier.businessName)}
                                                    </p>
                                                </div>
                                                <div className='flex flex-col justify-between whitespace-nowrap'>
                                                    <h3 className='text-sm text-[#4B4B1C]'>{supplier.businessName}</h3>
                                                    <p className='text-xs text-left font-medium text-[#969688]'>{supplier?.contactDetails?.[0]?.mobile}</p>
                                                </div>
                                            </div>
                                            <MoreOptions index={index} rfqData={{ ...rfqData, suppliers: [supplier] }} allSuppliers={rfqData?.suppliers?.filter((supplier) => supplier?.quotationReceived)} userProfile={userProfile} />
                                        </div>
                                        <h3 className='text-sm font-semibold text-left text-[#44441A] pb-1 pt-[15px]'>
                                            Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                                                withGst ? supplier?.vendorQuotations?.grandTotalWithGst : supplier?.vendorQuotations?.grandTotalWithoutGst
                                            )}
                                        </h3>
                                        <div className='flex justify-between items-center pb-[10px]'>
                                            <h3 className='text-xs text-left text-[#5CB218]'>Rank : {withGst ? supplier.vendorQuotations?.rankWithGst : supplier.vendorQuotations?.rank}</h3>
                                            {/* {lowerCount > 0 && <h3 className='text-xs text-left text-[#5CB218]'>{lowerCount} Line items lowest</h3>} */}
                                        </div>
                                        <div className='bg-[#1E1E08] h-[2px]'></div>
                                    </div>
                                </th>)
                        })}
                    </tr>
                </thead>
                {/* <CloseRfqOpt/> */}

                <tbody className='overflow-y-auto  bg-white mb-8 h-[300px]'>
                    {rfqData?.rfqType === 'material' && (
                        <MaterialRFQTable
                            rfqData={rfqData}
                            sortedSuppliers={sortedSuppliers}
                            withGst={withGst}
                            openDropdown={openDropdown}
                            handleMaterialDropdown={handleMaterialDropdown}
                            l1Data={l1Data}
                        />
                    )}
                    {rfqData?.rfqType === 'labour' && (
                        <LabourRFQTable
                            rfqData={rfqData}
                            sortedSuppliers={sortedSuppliers}
                            withGst={withGst}
                            openDropdown={openDropdown}
                            handleMaterialDropdown={handleMaterialDropdown}
                            l1Data={l1Data}
                        />
                    )}
                    {rfqData?.rfqType === 'contractor' && (
                        <ContractorRFQTable
                            rfqData={rfqData}
                            sortedSuppliers={sortedSuppliers}
                            withGst={withGst}
                            openDropdown={openDropdown}
                            handleMaterialDropdown={handleMaterialDropdown}
                        />
                    )}


                    {/* Sub total Row */}
                    <tr>
                        {/* fixed sub total */}
                        <td className="sticky left-0 px-4 bg-white w-[300px]" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                            <p className=' w-full text-[#004491] py-1 bg-[#E7EAEF]  text-xs px-2  '>{
                                rfqData?.rfqType === 'material'
                                    ? 'Material Amount'
                                    : rfqData?.rfqType === 'labour'
                                        ? 'Total Basic Amount'
                                        : rfqData?.rfqType === 'contractor'
                                            ? 'Sub Total'
                                            : null
                            }</p>
                        </td>

                        {/* sub total in quotation */}
                        {sortedSuppliers?.map((supplier, index) => {
                            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                            return (
                                <td
                                    key={index}
                                    className={`w-[300px] px-2 border border-gray-300 border-b-transparent ${index < rfqData?.suppliers?.length - 1 ? 'border' : ''}`}

                                >
                                    <div className='flex justify-end'>
                                        <p className='text-xs bg-[#E7EAEF] w-fit text-right text-[#004491] px-2 py-1'>
                                            Rs. {withGst
                                                ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorQuotation?.totalWithGst ?? 0)
                                                : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorQuotation?.totalWithoutGst ?? 0) || 'N/A'}
                                        </p>
                                    </div>
                                </td>
                            );
                        })}
                    </tr>

                    {/* extra charges */}
                    {/* Extra Charges Header */}
                    {rfqData.otherCharges && <tr>
                        <td
                            className="sticky left-0 z-10 pt-8 px-6 bg-white w-[300px] border-r"
                            style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            <p className="w-full text-sm py-[5px] font-bold text-[#2D2D11]">Extra Charges</p>
                            <hr />
                        </td>
                        {sortedSuppliers?.map((supplier, index) => (
                            <td
                                key={index}
                                className={`w-[300px] px-5 border-b border-gray-300 ${index < sortedSuppliers?.length - 1 ? 'border' : ''}`}
                            >
                                {/* Placeholder for extra charges */}
                            </td>
                        ))}
                    </tr>
                    }
                    {/* Filter Charge Types based on rfqData.otherCharges */}
                    {Object.keys(rfqData.otherCharges || []).filter((chargeType) => rfqData.otherCharges[chargeType]).map((chargeType) => (
                        <tr key={chargeType}>
                            <td className="sticky left-0 z-10 px-6 bg-white w-[300px] border-r border-gray-300">
                                <p className='w-full text-xs py-[5px] text-[#2D2D11] font-normal'>
                                    {chargeType
                                        .replace(/([A-Z])/g, ' $1')
                                        .split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')
                                    }

                                </p>
                            </td>
                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                                const otherCharges = vendorQuotation?.otherCharges || {};
                                const chargeDetails = otherCharges[chargeType] || {};

                                return (
                                    <td
                                        key={index}
                                        className={`w-[300px] px-5 border-b border-gray-300 ${index < sortedSuppliers?.length - 1 ? 'border' : ''}`}
                                    >
                                        {/* Show both GST and total cost horizontally centered */}
                                        <div className="flex justify-between items-center space-x-2">
                                            <p className="text-xs font-normal text-[#777777]">{withGst && <span>GST: {chargeDetails.gst || 0} %</span>}
                                            </p>
                                            <p className="text-xs">
                                                Rs. {withGst
                                                    ? new Intl.NumberFormat('en-IN').format(chargeDetails.totalCost || 0)
                                                    : new Intl.NumberFormat('en-IN').format(chargeDetails.mrp || 0)}
                                            </p>
                                        </div>
                                    </td>

                                );
                            })}
                        </tr>
                    ))}

                    <tr>
                        {/* Sticky Left Column for "Extra Charges" */}
                        <td
                            className="sticky left-0 z-10 bg-white w-[300px] border-r"
                            style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            <p className="w-full text-xs py-2 px-6 text-[#2D2D11]">Total GST</p>
                            <hr />
                        </td>

                        {/* Mapping through suppliers to show their GST differences */}
                        {sortedSuppliers.map((supplier, index) => {
                            const vendorQuotation = supplier.vendorQuotations;
                            return (
                                <td key={index} className="border border-gray-300 text-xs text-end px-6 py-2">
                                    Rs. {new Intl.NumberFormat('en-IN').format(vendorQuotation?.grandTotalWithGst - vendorQuotation?.grandTotalWithoutGst || 0)}
                                </td>
                            );
                        })}
                    </tr>

                    {/* total amount */}
                    <tr>
                        <td className="sticky left-0 px-4 bg-white w-[300px]" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                            <p className='w-full text-xs py-[5px] text-[#007366] border-t border-[#007366]  px-2  bg-[#E0F0EE]'>{
                                rfqData?.rfqType === 'material'
                                    ? 'Total (Material Amount + Extra Charges)'
                                    : rfqData?.rfqType === 'labour'
                                        ? 'Total (Basic Amount + Extra Charges)'
                                        : rfqData?.rfqType === 'contractor'
                                            ? 'Total (Sub Total + Extra Charges)'
                                            : null
                            }</p>
                        </td>
                        {sortedSuppliers?.map((supplier, index) => {
                            const vendorQuotation = supplier.vendorQuotations;
                            return (
                                <td
                                    key={index}
                                    className={`w-[300px] border-b-transparent px-4 ${index < rfqData?.suppliers?.length - 1 ? 'border' : ''}`}
                                    style={{ borderBottom: 'none' }} // Ensure no bottom border
                                >
                                    <div className='flex justify-end bg-[#E0F0EE] border-t border-t-[#007366]'>
                                        <p className='text-xs text-[#007366] w-1/2 text-right px-1 py-1'>
                                            Rs. {withGst
                                                ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorQuotation?.grandTotalWithGst ?? 0)
                                                : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorQuotation?.grandTotalWithoutGst ?? 0)}
                                        </p>                                    </div>
                                </td>
                            );
                        })}
                    </tr>

                    {/* deviation from lowest quote */}
                    <tr>
                        <td className="sticky left-0 pt-8 px-6 bg-white w-[300px] border-r" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                            <p className='w-full text-sm py-[5px] font-bold border-b border-b-gray-300 text-[#2D2D11]'>Difference from Lowest Quote</p>
                            <hr />
                        </td>

                        {sortedSuppliers?.map((supplier, index) => (
                            <td
                                key={index}
                                className={`w-[300px] px-5 text-xs border-b border-gray-300 ${index < sortedSuppliers?.length - 1 ? 'border-b border-x' : ''}`}
                            >
                                {/* Placeholder for payment terms */}
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <td className="sticky left-0 px-6 text-sm bg-white w-[300px]" >

                        </td>

                        {sortedSuppliers?.map((supplier, index) => {
                            const vendorQuotation = supplier.vendorQuotations;

                            // Use the appropriate grand total based on withGst state
                            const grandTotal = withGst
                                ? vendorQuotation.grandTotalWithGst
                                : vendorQuotation.grandTotalWithoutGst;

                            // Calculate L1 supplier data
                            const l1Supplier = sortedSuppliers[0]; // Assuming sortedSuppliers is sorted by total
                            const l1Total = l1Supplier
                                ? (withGst
                                    ? l1Supplier.vendorQuotations.grandTotalWithGst
                                    : l1Supplier.vendorQuotations.grandTotalWithoutGst)
                                : 0;

                            // Calculate deviation
                            const deviation = l1Total ? grandTotal - l1Total : 0;
                            const deviationPercentage = l1Total ? ((deviation / l1Total) * 100).toFixed(2) : 0;

                            return (
                                <td
                                    key={index}
                                    className={`w-[300px] px-5 border-t border-gray-300 ${index < rfqData?.suppliers?.length - 1 ? 'border-t border-x' : ''}`}
                                >
                                    {index === 0 ? (
                                        <p className='text-xs text-right pt-2 font-medium'>Lowest Quote</p>
                                    ) : (
                                        <p className='text-xs text-right pt-2'>
                                            Rs. {new Intl.NumberFormat('en-IN').format(deviation)} ({deviationPercentage}%)
                                        </p>
                                    )}
                                </td>
                            );
                        })}
                    </tr>


                    {/* Negotiation Outcome */}
                    <tr>
                        <td className="sticky left-0 pt-8 px-6 bg-white w-[300px] border-r" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                            <p className='w-full text-sm pt-[5px] font-bold text-[#2D2D11]'>Negotiation Outcome </p>
                            <p className='w-full text-[10px] text-[grey]'>Difference between the last & first quote</p>
                            <hr />
                        </td>

                        {sortedSuppliers?.map((supplier, index) => (
                            <td
                                key={index}
                                className={`w-[300px] px-5 text-xs border-b border-gray-300 ${index < sortedSuppliers?.length - 1 ? 'border-b border-x' : ''}`}
                            >
                                {/* Placeholder for payment terms */}
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <td className="sticky left-0 px-6 text-sm bg-white w-[300px]" >

                        </td>

                        {sortedSuppliers?.map((supplier, index) => {
                            const vendorQuotation = supplier?.vendorQuotations?.quotationSets;

                            // Use the appropriate grand total based on withGst state
                            const firstQuotation = withGst
                                ? (vendorQuotation?.[0]?.totalWithGst + vendorQuotation?.[0]?.othersTotalWithGst) || 0
                                : (vendorQuotation?.[0]?.totalWithoutGst + vendorQuotation?.[0]?.othersTotalWithoutGst) || 0;

                            const lastQuotation = withGst
                                ? (vendorQuotation?.slice(-1)[0]?.totalWithGst + vendorQuotation?.slice(-1)[0]?.othersTotalWithGst) || 0
                                : (vendorQuotation?.slice(-1)[0]?.totalWithoutGst + vendorQuotation?.slice(-1)[0]?.othersTotalWithoutGst) || 0;

                            // Calculate difference and percentage
                            const difference = firstQuotation - lastQuotation;
                            const negotiationPercentage =
                                firstQuotation > 0 ? ((difference / firstQuotation) * 100).toFixed(2) : 0;

                            return (
                                <td
                                    key={index}
                                    className={`w-[300px] px-5 border-t border-gray-300 ${index < sortedSuppliers?.length - 1 ? "border-t border-x" : ""
                                        }`}
                                >
                                    {firstQuotation === lastQuotation ? (
                                        <p className="text-xs text-right pt-2 font-medium">Not Updated</p>
                                    ) : (
                                        <p className="text-xs text-right pt-2">
                                            Rs. {new Intl.NumberFormat('en-IN').format(difference)} ({negotiationPercentage}%)
                                        </p>
                                    )}
                                </td>
                            );
                        })}
                    </tr>

                    {/* attachment */}
                    <tr>
                        <td className="sticky pt-8 left-0 px-6 bg-white w-[300px] border-r" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                            <p className='w-full text-sm py-[5px] font-bold border-b border-b-gray-300 text-[#2D2D11]'>Vendor Attachments</p>
                        </td>
                        {sortedSuppliers?.map((supplier, index) => (
                            <td
                                key={index}
                                className={`w-[300px] px-5 border-b text-xs border-gray-300 ${index < sortedSuppliers?.length - 1 ? 'border-b border-x' : ''}`}
                            >
                                {/* Placeholder for payment terms */}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="sticky left-0 px-6 pt-3 text-xs bg-white w-[300px] border-r">
                            {/* {rfqData?.paymentTerms} */}
                        </td>
                        {sortedSuppliers?.map((supplier, index) => {
                            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                            return (
                                <td className={`w-[300px] px-5 py-2 border-t border-b-0 border-gray-300 ${index < rfqData?.suppliers?.length - 1 ? 'border' : ''}`}>
                                    {vendorQuotation.attachments && vendorQuotation.attachments.length > 0 ? (
                                        <div >
                                            {/* <h3 className="text-xs font-medium text-[#434343]">Attachments</h3> */}
                                            <div className="space-y-2">
                                                {vendorQuotation.attachments.map((attachment, index) => {
                                                    const lastIndex = attachment.Name.lastIndexOf('.'); // Extract file extension
                                                    const path = lastIndex !== -1 ? attachment.Name.substring(lastIndex + 1) : null;
                                                    return (
                                                        <div key={index} className="flex justify-between items-center mt-2">
                                                            <div className="px-1 flex items-center justify-center border border-gray-300 rounded-full py-1 space-x-1 text-[#434343]">
                                                                {/* Dynamic icon based on file type */}
                                                                <img src={`/assets/icons/${path}.png`} alt={path} className="h-5 w-5" />
                                                                {/* Document name now clickable */}
                                                                <a
                                                                    href={attachment.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    download={attachment.Name}
                                                                    className="text-xs truncate text-[#434343] hover:underline"
                                                                >   
                                                                    {attachment.Name}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : <p className='text-xs text-right'>N/A</p>}
                                </td>
                            );
                        })}
                    </tr>


                    {/* payment terms */}
                    <tr>
                        <td className="sticky pt-8 left-0 px-6 bg-white w-[300px] border-r" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                            <p className='w-full text-sm py-[5px] font-bold border-b border-b-gray-300 text-[#2D2D11]'>Payment Terms</p>
                        </td>
                        {sortedSuppliers?.map((supplier, index) => (
                            <td
                                key={index}
                                className={`w-[300px] px-5 border-b text-xs border-gray-300 ${index < sortedSuppliers?.length - 1 ? 'border-b border-x' : ''}`}
                            >
                                {/* Placeholder for payment terms */}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="sticky left-0 px-6 pt-3 text-xs bg-white w-[300px] border-r" dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms || 'N/A' }}>
                            {/* {rfqData?.paymentTerms} */}
                        </td>
                        {sortedSuppliers?.map((supplier, index) => {
                            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                            return (
                                <td className={`w-[300px] px-5 py-2 border-t border-b-0 border-gray-300 ${index < rfqData?.suppliers?.length - 1 ? 'border' : ''}`}>
                                    {/* <p className='text-sm py-2 '> <b>Counter Offer :</b>   {vendorQuotation?.agreedToPaymentTerms ? "Agreed to payment terms" : vendorQuotation?.counterPaymentTerms}</p> */}
                                    {vendorQuotation?.agreedToPaymentTerms ? <p className='text-xs text-right py-2 '>Agreed to payment terms</p>
                                        : <p className='text-xs py-2 self-start'> <b>Counter Offer :</b>   {vendorQuotation?.counterPaymentTerms || ""}</p>}
                                </td>
                            );
                        })}
                    </tr>

                    {/* vendor notes */}
                    <tr>
                        <td className="sticky left-0 px-6 bg-white w-[300px] pt-8 border-r" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                            <p className='w-full text-sm py-[5px] font-bold border-b border-b-gray-300 text-[#2D2D11]'>Vendor Notes</p>
                            <hr />
                        </td>
                        {sortedSuppliers?.map((supplier, index) => (
                            <td
                                key={index}
                                className={`w-[300px] px-5 border-b text-xs border-gray-300 ${index < rfqData?.suppliers?.length - 1 ? 'border-b border-x' : ''}`}
                            >
                                {/* Placeholder for payment terms */}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="sticky left-0 px-6 text-sm bg-white w-[300px]" >
                            {/* Payment 60 days PDC */}
                        </td>
                        {sortedSuppliers?.map((supplier, index) => {
                            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                            return (
                                <td className={`w-[300px] px-5 py-2 border-t border-gray-300 ${index < rfqData?.suppliers?.length - 1 ? 'border' : ''}`}>
                                    {/* <p className='text-sm py-2 font-bold'>Counter Offer: <span className='font-normal'> {vendor.paymentTerm}</span></p> */}
                                    <p className='text-xs text-right py-2 '> {vendorQuotation?.notes || 'N/A'}</p>

                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </div >
    );
}

export default ComparisontableTesting;
