import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { tr } from 'date-fns/locale';
import StaticPopupNew from 'components/StaticPopupNew';
import StaticPopup from 'components/StaticPopup';



const getFormattedUnit = (unit) => {
    switch (unit) {
        case "Sq. Feet(SFT)":
            return "sq. ft.";
        case "Running Meter(RMT)":
            return "rmt";
        case "Sq. Meter":
            return "sq. mtr.";
        case "Numbers(Qty)":
            return "nos.";
        case "Cubic Meters(CUM)":
            return "cum";
        case "Kilograms(Kgs)":
            return "kgs";
        case "Metric Ton(M.Ton)":
            return "m. ton";
        case "Liters":
            return "ltrs";
        case "Bags":
            return "bags";
        case "Cubic Foot(CFT)":
            return "cft";
        default:
            return "";
    }
};

const formatChargeKey = (key) => {
    // Add space before uppercase letters and convert the string to proper case
    return key
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
};



const FullComparison = () => {
    const location = useLocation();
    const rfqData = location.state?.rfqData;
    const { rfqType, suppliers, otherCharges } = rfqData || {};
    const [expandedSections, setExpandedSections] = useState([]);
    const [openSpecsPopup, setOpenSpecsPopup] = useState(false);
    const [selectedSpecs, setSelectedSpecs] = useState("");
    const [popupHeader, setPopupHeader] = useState("")

    const handleOpenPopup = () => {
        setOpenSpecsPopup(true);
    };

    const toggleSection = (sectionIdx) => {
        if (expandedSections.includes(sectionIdx)) {
            setExpandedSections(expandedSections.filter((idx) => idx !== sectionIdx));
        } else {
            setExpandedSections([...expandedSections, sectionIdx]);
        }
    };

    // Function to open the popup with full specifications
    const openFullSpecs = (specifications, popupHeader) => {
        setSelectedSpecs(specifications); // Set the full specifications
        setOpenSpecsPopup(true); // Open the popup
        setPopupHeader(popupHeader);
    };

    // Function to close the popup
    const closeSpecsPopup = () => {
        setOpenSpecsPopup(false); // Close the popup
    };

    const sortedSuppliers = rfqData.suppliers
        .filter(supplier => supplier?.quotationReceived)
        .sort((a, b) => {
            const rankA = a.vendorQuotations?.rank; // Only keep rank
            const rankB = b.vendorQuotations?.rank;

            // Convert ranks like "L1", "L2" to numerical values for comparison
            const numA = parseInt(rankA.substring(1)); // Extract the number part (e.g., "1" from "L1")
            const numB = parseInt(rankB.substring(1));

            return numA - numB; // Ascending order
        });


    const navigate = useNavigate()

    const getLineItems = () => {
        if (rfqType === "material") {
            return rfqData?.materials?.map(item => ({
                name: item.name,
                quantity: item.quantity,
                unit: item.unit,
            }));
        } else if (rfqType === "labour") {
            return rfqData?.labourDetails.map(item => ({
                name: item.skill,
                quantity: item.quantity,
                unit: item.unit,

            }));
        } else if (rfqType === 'contractor') {
            return rfqData?.providingFixingDetails || [];
        }
        return [];
    };

    const getExtraCharges = () => {
        return Object.keys(otherCharges || {}).filter(charge => otherCharges[charge]);
    };

    const lineItems = getLineItems();
    const extraChargesKeys = getExtraCharges();

    const getVendorQuotationData = (supplier, index) => {
        const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
        let vendorItem = {};
        if (rfqType === "material") {
            vendorItem = vendorQuotation?.materialDetails?.[index] || {};
        } else if (rfqType === "labour") {
            vendorItem = vendorQuotation?.labourItems?.[index] || {};
        } else if (rfqType === "contractor") {
            vendorItem = vendorQuotation?.contractorDetails || [];
        }

        return {
            ...vendorItem,
            subTotal: vendorQuotation?.totalWithoutGst,
            grandTotal: supplier.vendorQuotations?.grandTotalWithGst,
            gstTotal: supplier.vendorQuotations?.grandTotalWithGst - supplier.vendorQuotations?.grandTotalWithoutGst,
            totalBaseAmount: vendorQuotation?.totalWithoutGst + vendorQuotation?.othersTotalWithoutGst,
            otherCharges: vendorQuotation?.otherCharges || {}
        };
    };

    const getVendorQuotationDataContractor = (supplier, sectionIdx, scopeIdx, materialIdx) => {
        const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
        if (!vendorQuotation) return {}; // Handle missing quotations

        const contractorDetails = vendorQuotation?.contractorDetails || [];
        const sectionData = contractorDetails[sectionIdx]; // Get the section by index

        // Handle Section Level
        if (scopeIdx === undefined && materialIdx === undefined) {
            return {
                sectionTotalExclGst: sectionData?.sectionTotalExclGst || 0,
            };
        }

        // Handle Scope Level
        const scopeData = sectionData?.scopes[scopeIdx]; // Get the scope by index
        if (materialIdx === undefined) {
            return {
                basePrice: scopeData?.basePrice || '-',
                installRate: '-', // No installation rate for scopes
                materialCost: '-', // No material cost for scopes
                installCost: '-', // No install cost for scopes
                gst: scopeData?.gst || '0',
                comments: scopeData?.comments || '-',
                totalExclGst: scopeData?.totalExclGst || '-',
            };
        }

        // Handle Material Level
        const materialData = scopeData?.materials[materialIdx]; // Get the material by index
        return {
            basePrice: materialData?.basePrice || '-',
            installRate: materialData?.installationRate || '-',
            gst: materialData?.gst || '-',
            materialCost: materialData?.netPrice || '-',
            installCost: materialData?.installationCost || '-',
            totalExclGst: materialData?.totalExclGst || '-',
            remarks: materialData?.remarks || '-',
        };
    };


    return (
        <div className=' h-screen bg-white'>
            <div className='sticky top-0 shadow-md bg-white w-full z-50 py-3'>
                <div className='grid grid-cols-5 '>
                    <div className='px-12 col-span-1'>
                        <button onClick={(() => navigate(-1))} className='bg-[#EDEDE9] px-6 py-2 text-xs'>Back</button>
                    </div>
                    <div className='w-full col-span-3'>
                        <h3 className='text-sm text-[#484848] text-center font-semibold pb-1'>Full Comparison</h3>
                    </div>
                    <p></p>
                </div>
                <div className='grid grid-cols-6 px-10 pt-2 w-full text-xs mx-auto'>
                    <p className='col-span-3'><span className='text-[#6b7280]'>RFQ Name: </span>{rfqData?.name}</p>
                    <p className='col-span-2'><span className='text-[#6b7280]'>Project Name: </span> {rfqData?.projectName}</p>
                    <p className=''><span className='text-[#6b7280]'>Location: </span> {rfqData?.location}</p>
                </div>
            </div>
            <div className=" overflow-x-auto overflow-y-auto bg-white" style={{ height: "calc(100vh - 80px)" }}>
                <table className=" max-w-[800px] min-w-[800px]">
                    <thead>
                        {/* Header for Vendor Names */}
                        <tr>
                            <td className="sticky min-w-[400px] max-w-[400px] left-0 z-0 bg-white"></td>
                            {sortedSuppliers?.map((supplier, idx) => (
                                <th key={idx} className="py-4 px-2 m-2 text-left">
                                    <div className="flex items-center justify-center">
                                        {/* <hr className="border-t border-[#070707] flex-grow" /> */}
                                        <span className="mx-2 text-center font-bold text-xs">
                                            {supplier.businessName}<br /> (Rank: {supplier.vendorQuotations?.rank} - Rs. {new Intl.NumberFormat('en-IN').format(supplier.vendorQuotations?.grandTotalWithoutGst.toFixed(2))}<span className="text-[10px] font-normal"> excl. GST</span>)
                                        </span>

                                        {/* <hr className="border-t border-[#070707] flex-grow" /> */}
                                    </div>
                                </th>
                            ))}
                        </tr>

                        {/* Line Item Headers */}
                        <tr>
                            <th className="sticky left-0 bg-white text-[10px] font-normal text-left text-gray-500">
                                {rfqType === "material" && (
                                    <>
                                        <div className='grid grid-cols-7'>
                                            <p className='text-[10px] border-t border-x p-1 pl-4'>Sr.</p>
                                            <p className="text-[#707070] col-span-4 text-[10px] border-t border-r p-1">Material</p>
                                            <p className='text-[10px] col-span-2 border-t border-r p-1'>Qty</p>
                                        </div>
                                    </>
                                )}

                                {rfqType === "labour" && (
                                    <>
                                        <div className='grid grid-cols-7'>
                                            <p className='text-[10px] border-t border-x p-1 pl-4'>Sr#</p>
                                            <p className="col-span-4 border-t border-r text-[#707070] text-[10px] p-1">Skill</p>
                                            <p className='col-span-2 border-t border-r text-[10px] px-2 py-1'>Qty</p>
                                        </div>
                                    </>
                                )}

                                {rfqType === "contractor" && (
                                    <div className='grid grid-cols-7 h-full'>
                                        <p className='col-span-3 text-center h-full pt-[7px] px-1 border-t border-x border-gray-300 text-[10px] pb-6'>Description</p>
                                        <p className='col-span-2 text-center h-full py-2 px-1 border-t border-r border-gray-300 text-[10px]'>Qty</p>
                                        <p className='col-span-2 text-center h-full py-2 px-1 border-t border-r border-gray-300 text-[10px]'>Brands</p>
                                    </div>
                                )}
                            </th>
                            {sortedSuppliers?.map((_, idx) => (
                                <td key={idx} >
                                    <div className='grid grid-cols-14 min-w-[500px] max-w-[500px] text-[10px] text-gray-500'>
                                        {rfqType === "material" && (
                                            <>
                                                <p className='col-span-5 text-right p-1 border overflow-x-auto border-t border-l-0 border-b-0 border-r border-gray-300'>Remarks</p>
                                                <p className='col-span-2 text-right p-1 border overflow-x-auto border-t border-l-0 border-b-0 border-r border-gray-300'>MRP</p>
                                                <p className='col-span-1 text-right p-1 border border-gray-300 border-t  border-l-0 border-b-0 border-r'>Disc</p>
                                                <p className='col-span-2 text-right p-1 border border-gray-300 border-t border-l-0 border-b-0 border-r'>Rate</p>
                                                {/* <p className='col-span-3 border border-gray-300'>Delivery Date</p> */}
                                                <p className='col-span-3 text-right p-1 border border-gray-300 border-t border-l-0 border-b-0 border-r'>Basic Amt</p>
                                                <p className='col-span-1 text-right p-1 border border-gray-300 border-t border-l-0 border-b-0 border-r'>GST</p>
                                            </>
                                        )}
                                        {rfqType === "labour" && (
                                            <>
                                                <p className='col-span-4 text-right border p-1  border-gray-300 border-b-0 border-t border-l-0'>Rate</p>
                                                <p className='col-span-3 text-right border p-1 border-gray-300 border-b-0 border-t border-l-0'>Qty</p>
                                                <p className='col-span-4 text-right border p-1 border-gray-300 border-b-0 border-t border-l-0'>Total</p>
                                                <p className='col-span-3 text-right border p-1 border-gray-300 border-b-0 border-t border-l-0'>GST (%)</p>
                                                {/* <p className='col-span-3 text-center border border-white'>Start Date</p> */}
                                            </>
                                        )}
                                        {rfqType === 'contractor' && (
                                            <>
                                                <p className="col-span-3 text-center px-1 py-2 border-gray-300 border-t border-r">Remarks</p>
                                                <p className="col-span-2 text-center px-1 py-2 border-gray-300 border-t border-r">Basic Rate</p>
                                                <p className="col-span-2 text-center px-1 py-2 border-gray-300 border-t border-r">Installation Rate</p>
                                                <p className="col-span-1 text-center px-1 py-2 border-gray-300 border-t border-r">GST</p>
                                                <p className="col-span-2 text-center px-1 py-2 border-gray-300 border-t border-r">Material Cost</p>
                                                <p className="col-span-2 text-center px-1 py-2 border-gray-300 border-t border-r">Installation Cost</p>
                                                <p className="col-span-2 text-center py-2 border-gray-300 border-t border-r">Total<br />(excl.GST)</p>
                                            </>
                                        )}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </thead>

                    <tbody className='bg-white'>
                        {/* Line Items Mapping */}
                        {rfqType === 'material' && lineItems.map((item, index) => (
                            <tr key={index} className='bg-white'>
                                <td className="sticky left-0 text-[10px] border">
                                    <div className="grid grid-cols-7 bg-white">
                                        <p className='px-1 py-2 border-r h-full pl-4'>{index + 1}</p>
                                        <p className="px-1 py-2 col-span-4 border-r h-full ">{ReactHtmlParser(item.name)}</p>
                                        <p className="px-1 py-2 col-span-2 border-r h-full break-words">{item.quantity} {item.unit}</p>
                                    </div>
                                </td>
                                {sortedSuppliers?.map((supplier, idx) => {
                                    const vendorData = getVendorQuotationData(supplier, index);
                                    return (
                                        <td key={idx} className={`border text-right ${idx < sortedSuppliers.length - 1 ? 'border-r border-r-[#707070]' : ''}`}>
                                            <div className="grid grid-cols-14 text-[10px]">
                                                <p className="col-span-5 px-1 border-r">
                                                    {vendorData.remark ? (
                                                        <>
                                                            <div className='flex space-x-1'>
                                                                {/* <p>{index + 1}.</p> */}
                                                                <p> {ReactHtmlParser(
                                                                    vendorData?.remark?.length > 25
                                                                        ? vendorData?.remark?.slice(0, 25) + "..."
                                                                        : vendorData?.remark || "-"
                                                                )}
                                                                </p>
                                                            </div>
                                                            {vendorData.remark.length > 20 && (
                                                                <button className="text-gray-500 self-start pl-2 flex items-start justify-start italic" onClick={() => openFullSpecs(ReactHtmlParser(vendorData?.remark), "Remark")}>
                                                                    Read More
                                                                </button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </p>
                                                <p className="col-span-2 border-r px-1">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorData.basePrice || 0)}</p>
                                                <p className="col-span-1 border-r px-1">{vendorData.discount || 0}%</p>
                                                <p className="col-span-2 border-r px-1">{vendorData?.basePrice && vendorData?.discount !== undefined
                                                    ? new Intl.NumberFormat('en-IN').format(vendorData.basePrice - (vendorData.basePrice * vendorData.discount / 100))
                                                    : 0}</p>
                                                <p className="col-span-3 border-r px-1">{vendorData?.priceWithoutGst > 0 ? new Intl.NumberFormat('en-IN').format(vendorData?.priceWithoutGst?.toFixed(2)) : 0}</p>
                                                <p className="col-span-1 border-r px-1">{vendorData.gst || 0}%</p>
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}

                        {rfqType === 'labour' && lineItems.map((item, index) => (
                            <tr key={index}>
                                <td className="sticky left-0 py-1 text-[10px] border">
                                    <div className="grid grid-cols-7">
                                        <p className='pl-4'>{index + 1}</p>
                                        <p className="col-span-4 break-words">{ReactHtmlParser(item.name)}</p>
                                        <p className="col-span-2 pl-2 break-words">{item.quantity} {item.unit}</p>
                                    </div>
                                </td>
                                {sortedSuppliers?.map((supplier, idx) => {
                                    const data = getVendorQuotationData(supplier, index);
                                    return (
                                        <td key={idx} className=" py-1 border text-right">
                                            <div className="grid grid-cols-14 text-[10px]">
                                                <p className="col-span-4 p-1 border-gray-300">{new Intl.NumberFormat('en-IN').format(data.labourRate) || '-'}</p>
                                                <p className="col-span-3 p-1 border-gray-300">{data.quantity || '-'}</p>
                                                <p className="col-span-4 p-1 border-gray-300">{new Intl.NumberFormat('en-IN').format(data?.priceWithoutGst?.toFixed(2)) || ''}</p>
                                                <p className="col-span-3 p-1">{data.gst || '-'}%</p>
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}

                        {rfqType === 'contractor' &&
                            lineItems.map((section, sectionIdx) => (
                                <React.Fragment key={sectionIdx}>
                                    {/* Section Row */}
                                    <tr onClick={() => toggleSection(sectionIdx)} className="cursor-pointer border-r border-y border-gray-300 bg-gray-200">
                                        <td className="flex justify-between items-center sticky left-0 px-4 py-1 text-[10px] border-x border-gray-300">
                                            <strong>{section.sectionName}</strong>
                                            <img
                                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                                                alt="toggle"
                                                className={`w-2 h-2 transition-transform duration-200 ${expandedSections.includes(sectionIdx) ? "rotate-180" : "rotate-0"
                                                    }`}
                                            />
                                        </td>
                                        {sortedSuppliers?.map((supplier, idx) => {
                                            const data = getVendorQuotationDataContractor(supplier, sectionIdx);
                                            return (
                                                <td key={idx} className=" bg-gray-200 text-[10px] text-right">
                                                    <div className=" h-full">
                                                        {/* <p className="border-r col-span-4 border-gray-300"></p>
                                                        <p className="border-r col-span-1 border-gray-300"></p>
                                                        <p className="border-r col-span-2 border-gray-300"></p>
                                                        <p className="border-r col-span-1 border-gray-300"></p>
                                                        <p className="border-r col-span-2 border-gray-300"></p>
                                                        <p className="border-r col-span-2 border-gray-300"></p> */}
                                                        <p className='text-right py-1 pr-1'>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(data.sectionTotalExclGst || 0)}</p>
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>

                                    {/* Expanded Content */}
                                    {expandedSections.includes(sectionIdx) &&
                                        section.scopes.map((scope, scopeIdx) => (
                                            <React.Fragment key={scopeIdx}>
                                                {/* Scope Rows */}
                                                <tr className="h-full">
                                                    {/* Left Sticky Column */}
                                                    <td className="sticky left-0 h-full text-[10px] border">
                                                        <div className="grid grid-cols-7 h-full w-full">
                                                            <div className="col-span-3 space-x-1 p-1 border-r border-gray-300 w-full h-full ">
                                                                {scope.scopeOfWork ? (
                                                                    <>
                                                                        <div className='flex space-x-1'>
                                                                            <p>{scopeIdx + 1}.</p>
                                                                            <p> {ReactHtmlParser(
                                                                                scope?.scopeOfWork && scope?.scopeOfWork?.length > 25
                                                                                    ? scope?.scopeOfWork?.slice(0, 25) + "..."
                                                                                    : scope?.scopeOfWork || "N/A"
                                                                            )}
                                                                            </p>
                                                                        </div>
                                                                        {scope.scopeOfWork.length > 20 && (
                                                                            <button className="text-gray-500 italic ml-8" onClick={() => openFullSpecs(ReactHtmlParser(scope.scopeOfWork), "Scope of Work")}>
                                                                                Read More
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    "N/A"
                                                                )}
                                                            </div>
                                                            <div className="border-r p-1 col-span-2 border-gray-300 h-full  break-words">
                                                                <p>
                                                                    {scope.quantity} {scope.unit}
                                                                </p>
                                                            </div>
                                                            <div className="col-span-2 p-1 border-gray-300 break-words h-full ">
                                                                <p>{ReactHtmlParser(scope.preferredBrands.join(', ') || '-')}</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {/* Supplier Columns */}
                                                    {sortedSuppliers?.map((supplier, idx) => {
                                                        const data = getVendorQuotationDataContractor(supplier, sectionIdx, scopeIdx);
                                                        return (
                                                            <td
                                                                key={idx}
                                                                className="h-full text-[10px] border text-right align-text-top"
                                                            >
                                                                <div className="grid grid-cols-14 h-full">
                                                                    <p className="h-full border-r p-1 text-left break-words col-span-3 border-gray-300">{ReactHtmlParser(data.comments)}</p>
                                                                    <p className="col-span-2 border-r p-1 border-gray-300">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((data.basePrice > 0 ? data.basePrice : data.totalExclGst) || 0)}</p>
                                                                    <p className="col-span-2 border-r p-1 border-gray-300">-</p>
                                                                    <p className="col-span-1 border-r p-1 border-gray-300">{data.gst}%</p>
                                                                    <p className="col-span-2 border-r p-1 border-gray-300">-</p>
                                                                    <p className="col-span-2 border-r p-1 border-gray-300">-</p>
                                                                    <p className='col-span-2 p-1'>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(data.totalExclGst || '-')}</p>
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>


                                                {scope.materials.length > 0 && <tr>
                                                    <td className=' sticky left-0 bg-gray-100 border-b'>
                                                        <p className='text-[10px] px-4 py-1'>Material List for Scope {scopeIdx + 1}</p>
                                                    </td>
                                                    <td className='text-[10px] px-4 bg-gray-100 h-full'>
                                                    </td>
                                                </tr>}
                                                {/* Material Rows */}
                                                {scope.materials.map((material, materialIdx) => (
                                                    <tr key={materialIdx} className="h-full align-top">
                                                        {/* Left Sticky Column */}
                                                        <td className="sticky left-0 h-full grid grid-cols-7 text-[10px] border-b p-0 align-top">
                                                            {/* <div className=" h-full"> */}
                                                            {/* <p className="border-r text-right pr-1 py-1 h-full"></p> */}
                                                            <div className="col-span-3 border-x px-1 pt-1 pb-5 flex space-x-2 h-full">
                                                                <p>{scopeIdx + 1}.{materialIdx + 1}</p>
                                                                <p>{ReactHtmlParser(material.name)}</p>
                                                            </div>
                                                            <p className="border-r col-span-2 px-1 py-1 h-full break-words truncate">
                                                                {material.quantity} {material.unit}
                                                            </p>
                                                            <p className="col-span-2 px-1 h-full break-words">{material.brands.join(', ')}</p>
                                                            {/* </div> */}
                                                        </td>

                                                        {/* Supplier Columns */}
                                                        {sortedSuppliers?.map((supplier, idx) => {
                                                            const data = getVendorQuotationDataContractor(supplier, sectionIdx, scopeIdx, materialIdx); // Pass all indices
                                                            return (
                                                                <td key={idx} className="h-full text-[10px] border p-0 align-top">
                                                                    <div className="grid grid-cols-14 h-full">
                                                                        <div className="text-left max-h-[100px] truncate overflow-y-auto col-span-3 border-r px-1 py-1">
                                                                            <div>
                                                                                {ReactHtmlParser(
                                                                                    data?.remarks && data.remarks.length > 25
                                                                                        ? data.remarks.slice(0, 25) + "..."
                                                                                        : data?.remarks || "N/A"
                                                                                )}
                                                                                {data?.remarks && data.remarks.length > 25 && (
                                                                                    <button className="text-gray-500 italic" onClick={() => openFullSpecs(ReactHtmlParser(data.remarks), "Material Remark")}>
                                                                                        Read More
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <p className="col-span-2 text-right border-r px-1 py-1 break-words">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.basePrice || '-')}</p>
                                                                        <p className="col-span-2 text-right border-r px-1 py-1  break-words">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.installRate || '-')}</p>
                                                                        <p className="col-span-1 text-right border-r px-1 pt-1 pb-5">{data.gst || 0}%</p>
                                                                        <p className="col-span-2 text-right border-r px-1 py-1 break-words">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.materialCost || '-')}</p>
                                                                        <p className="col-span-2 text-right border-r px-1 py-1 break-words">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.installCost || '-')}</p>
                                                                        <p className="col-span-2 text-right px-1 py-1 break-words">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.totalExclGst || '-')}
                                                                        </p>
                                                                    </div>
                                                                </td>

                                                            );
                                                        })}
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            ))}

                        {/* Sub Total Row */}
                        {/* <tr>
                            <td className="sticky left-0 bg-white shadow-md pt-3 pl-4 min-w-[200px] text-[10px] font-semibold">Sub Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full mt-3 ${idx < sortedSuppliers.length - 1 ? 'border-r border-[#707070]' : ''}`}>
                                        <p className="font-bold text-xs text-right mt-1 text-[#004491]">
                                            <span className='bg-[#E7EAEF] text-[10px] px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.subTotal)}</span> 
                                        </p>
                                    </td>
                                );
                            })}
                        </tr> */}

                        {/* Extra Charges Section */}
                        {extraChargesKeys && extraChargesKeys.length > 0 && (
                            <>
                                <tr className='bg-white'>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td key={idx} className={`p-2 border-t bg-white ${idx < sortedSuppliers.length - 1 ? 'border-x' : ''}`}></td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="sticky left-0 bg-white px-4"></td>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td key={idx} className={`px-2 border-l ${idx < sortedSuppliers?.length - 1 ? '' : ''}`}>
                                            <div className="grid grid-cols-3 uppercase pl-2 text-[10px] ml-auto text-right w-1/2 text-gray-500">
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr >
                                    <td className="sticky left-0 text-[#5D5D5D] font-semibold text-[10px] bg-white px-4 border">Extra Charges</td>
                                    <td>
                                        <div className='text-[10px] text-[#6b7280] px-2 border-y border-r w-full grid grid-cols-14'>
                                            <td className='col-span-11 text-center py-1 border-r'>Remarks</td>
                                            <td className='col-span-3 text-right py-1'> Cost (excl. GST)</td>
                                        </div>
                                    </td>
                                </tr>

                                {extraChargesKeys.map((chargeKey, index) => (
                                    <tr key={index}>
                                        <td className="sticky left-0 border-l border-b border-gray-300 bg-white shadow-md min-w-[200px] px-4">
                                            <div className="flex items-center text-[10px] text-center">
                                                <p className=''>{formatChargeKey(chargeKey)}</p>
                                            </div>
                                        </td>
                                        {sortedSuppliers?.map((supplier, vendorIdx) => {
                                            const vendorData = getVendorQuotationData(supplier, 0);
                                            return (
                                                <td key={vendorIdx} className={`border-b border-gray-300 ${vendorIdx < sortedSuppliers.length - 1 ? 'border-r border-gray-600' : ''}`}>
                                                    <div className="text-[10px] border-x px-2 text-right grid grid-cols-14 ">
                                                        <td className='p-1 col-span-11 border-r text-left'> {ReactHtmlParser(
                                                            vendorData.otherCharges[chargeKey]?.remark?.length > 100
                                                                ? vendorData.otherCharges[chargeKey]?.remark.slice(0, 100) + "..."
                                                                : vendorData.otherCharges[chargeKey]?.remark || "-"
                                                        )}
                                                            {vendorData.otherCharges[chargeKey]?.remark?.length > 100 && (
                                                                <button
                                                                    className="text-gray-500 italic "
                                                                    onClick={() =>
                                                                        openFullSpecs(
                                                                            ReactHtmlParser(vendorData.otherCharges[chargeKey]?.remark || "-"),
                                                                            "Remark"
                                                                        )
                                                                    }
                                                                >
                                                                    Read More
                                                                </button>
                                                            )}</td>
                                                        <td className='p-1 col-span-3'>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(vendorData.otherCharges[chargeKey]?.mrp || 0)}</td>
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </>
                        )}


                        {/* Grand Total Row */}
                        {/* <tr>
                            <td className="sticky left-0 text-xs px-4 bg-white shadow-md min-w-[200px] font-semibold">Grand Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}}>
                                        <div className="border-t border-t-[#004491] mx-[10px] mt-2">
                                            <p className="font-bold text-xs text-right mt-[7px] text-[#004491]">
                                                <span className='bg-[#E0F0EE] px-6 py-2'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.grandTotal)}</span>
                                            </p>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr> */}

                        {/* Sub Total Row */}
                        <tr>
                            <td className="sticky left-0 bg-white border-b shadow-md pt-3 pl-4 min-w-[200px] text-[10px] font-semibold">Total Basic Amount</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full border-b mt-3  ${idx < sortedSuppliers.length - 1 ? 'border-r border-r-[#707070]' : ''}`}>
                                        {/* <div className="border-t border-t-[#004491] mx-[10px] mt-2"> */}
                                        <p className="font-bold mx-[8px] text-xs text-right mt-1 text-[#004491]">
                                            <span className='bg-[#E7EAEF] text-[10px] px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.totalBaseAmount)}</span> {/* Adjusted padding */}
                                        </p>
                                        {/* </div> */}
                                    </td>
                                );
                            })}
                        </tr>

                        {/* GST Total Row */}
                        <tr>
                            <td className="sticky left-0 text-[10px] px-4 border-b bg-white shadow-md min-w-[200px]">GST Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full border-b mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r border-r-[#707070]' : ''}`}>
                                        {/* <div className="border-t border-t-[#004491] mx-[10px] mt-2"> */}
                                        <p className=" mx-2 text-[10px] text-right mt-[10px]">
                                            <span className=' px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.gstTotal)}</span> {/* Adjusted padding */}
                                        </p>
                                        {/* </div> */}
                                    </td>
                                );
                            })}
                        </tr>

                        {/* Grand Total Row */}
                        <tr>
                            <td className="sticky left-0 text-[10px] border-b px-4 pb-8 bg-white shadow-md min-w-[200px] font-semibold">Total Quotation Value (inc. GST)</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full pb-8 border-b mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r border-r-[#707070]' : ''}`}>
                                        <div className="mx-[10px]">
                                            <p className="font-bold text-[10px] text-right mt-[7px] text-[#004491]">
                                                <span className='bg-[#E0F0EE] px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.grandTotal)}</span> {/* Adjusted padding */}
                                            </p>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 text-[10px] py-2 px-4 border-t bg-white shadow-md min-w-[200px] font-semibold">
                                Difference from Lowest
                            </td>
                            {sortedSuppliers.map((supplier, idx) => {
                                const vendorQuotation = supplier.vendorQuotations;

                                // Use the appropriate grand total based on withGst state
                                const grandTotal = vendorQuotation.grandTotalWithGst


                                // Calculate L1 supplier data
                                const l1Supplier = sortedSuppliers[0]; // Assuming sortedSuppliers is sorted by total
                                const l1Total = l1Supplier ? l1Supplier.vendorQuotations.grandTotalWithGst : 0;

                                // Calculate deviation
                                const deviation = l1Total ? grandTotal - l1Total : 0;
                                const deviationPercentage = l1Total ? ((deviation / l1Total) * 100).toFixed(2) : 0;
                                // map the data here
                                return (
                                    <td key={idx} className={`border-t ${idx < sortedSuppliers.length - 1 ? 'border-r border-r-[#707070]' : ''}`}>
                                        {idx === 0 ? (
                                            <p className='text-[10px] text-right py-2 mr-2'>Lowest Quote</p>
                                        ) : (
                                            <p className='text-[10px] text-right py-2 mr-2'>
                                                Rs. {new Intl.NumberFormat('en-IN').format(deviation)} ({deviationPercentage}%)
                                            </p>
                                        )}
                                    </td>
                                )
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 border-t px-4 bg-white w-[300px] pb-4" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                                <p className='w-full text-[10px] pt-[5px] font-bold text-[#2D2D11]'>Negotiation Outcome </p>
                                <p className='w-full text-[10px] text-[grey]'>Difference between the last & first quote</p>
                            </td>

                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation = supplier?.vendorQuotations?.quotationSets;

                                // Use the appropriate grand total based on withGst state
                                const firstQuotation = vendorQuotation?.[0]?.totalWithoutGst + vendorQuotation?.[0]?.othersTotalWithoutGst || 0;

                                const lastQuotation = vendorQuotation?.slice(-1)[0]?.totalWithoutGst + vendorQuotation?.slice(-1)[0]?.othersTotalWithoutGst || 0;

                                // Calculate difference and percentage
                                const difference = firstQuotation - lastQuotation;
                                const negotiationPercentage =
                                    firstQuotation > 0 ? ((difference / firstQuotation) * 100).toFixed(2) : 0;

                                return (
                                    <td
                                        key={index}
                                        className={`w-[300px] border-t px-4 ${index < sortedSuppliers?.length - 1 ? "border-r border-r-[#707070]" : ""
                                            }`}
                                    >

                                        <td></td>
                                        {firstQuotation === lastQuotation ? (
                                            <p className="text-[10px] text-right pt-2 pb-4">Not Updated</p>
                                        ) : (
                                            <p className="text-[10px] text-right pt-2 pb-4">
                                                Rs. {new Intl.NumberFormat('en-IN').format(difference)} ({negotiationPercentage}%)
                                            </p>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 px-6 bg-white w-[300px] border-t" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                                <p className='w-full text-[10px] py-[5px] font-bold text-[#2D2D11]'>Payment Terms</p>
                            </td>
                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                                return (
                                    <td className={`w-[300px] px-5 py-2 border-t ${index < rfqData?.suppliers?.length - 1 ? 'border-r border-r-[#707070]' : ''}`}>
                                        {/* <p className='text-xs py-2 font-bold'>Counter Offer: <span className='font-normal'> {vendor.paymentTerm}</span></p> */}
                                        <p className='text-[10px] py-2 '> {vendorQuotation?.counterPaymentTerms || 'Agreed to payment terms'}</p>
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 px-6 bg-white w-[300px] border-t" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                                <p className='w-full text-[10px] py-[5px] font-bold text-[#2D2D11]'>Vendor Notes</p>
                            </td>
                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                                return (
                                    <td className={`w-[300px] px-5 py-2 border-t ${index < rfqData?.suppliers?.length - 1 ? 'border-r border-r-[#707070]' : ''}`}>
                                        {/* <p className='text-xs py-2 font-bold'>Counter Offer: <span className='font-normal'> {vendor.paymentTerm}</span></p> */}
                                        <p className='text-[10px] py-2 '> {vendorQuotation?.notes || 'N/A'}</p>
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table >
            </div >
            <StaticPopup
                openModal={openSpecsPopup}
                handleClose={closeSpecsPopup}
                width={"xl"}
                bodyStyle="pb-0 pt-0"
                className="px-0"
                header={popupHeader}
            >
                <div className="py-4">
                    <p>{selectedSpecs}</p> {/* Display the full specifications */}
                </div>
            </StaticPopup>
        </div >
    );
};

export default FullComparison;