import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorMessage, useFormikContext } from 'formik';
import ReactQuill from "react-quill";
import * as XLSX from 'xlsx';
import StaticPopup from "components/StaticPopup";
import LoadingModal from "../LoadingModal";
import SearchableDropdown from "./SearchableDropdown";
import { debounce } from "lodash";
import { UNITOPTIONS } from 'config';
const downloatTemplate = "https://storagereponeevaydevcdn.blob.core.windows.net/business/material_BulkUpload_Template.xlsx"


const AddMaterialInScope = ({ materials, onAddMaterial, sectionIndex, scopeIndex }) => {
    const { values, setFieldValue, errors, validateForm, setTouched } = useFormikContext();

    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [fileError, setFileError] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [localMaterials, setLocalMaterials] = useState(materials);


    const isMaterialEmpty = (material) => {
        return !material.name && !material.quantity && !material.unit && material.brands.length === 0 && !material.specifications;
    };

    const cleanUpMaterials = (materials) => {
        const filteredMaterials = materials.filter((material) => !isMaterialEmpty(material));
        if (filteredMaterials.length === 0) {
            return [{
                name: '',
                quantity: '',
                unit: '',
                brands: [],
                specifications: ''
            }];
        }
        return filteredMaterials;
    };

    const debouncedOnAddMaterial = useCallback(
        debounce((updatedMaterials) => {
            onAddMaterial(updatedMaterials, scopeIndex);
        }, 300),
        [onAddMaterial, scopeIndex]
    );

    const handleInputChange = (index, field, value) => {
        const updatedMaterials = localMaterials.map((material, i) =>
            i === index ? { ...material, [field]: value } : material
        );

        if (JSON.stringify(updatedMaterials) !== JSON.stringify(localMaterials)) {
            setLocalMaterials(updatedMaterials); // Update local state only if it’s different
            debouncedOnAddMaterial(updatedMaterials); // Propagate changes after debounce
        }
    };


    const handleBrandInputChange = (index, value) => {
        const updatedMaterials = localMaterials.map((material, i) =>
            i === index
                ? { ...material, brands: value.split(",").map((brand) => brand.trim()) }
                : material
        );

        setLocalMaterials(updatedMaterials); // Immediate update for UI
        debouncedOnAddMaterial(updatedMaterials); // Update parent state after debounce
    };
    

    const removeRow = (index) => {
        const updatedMaterials = localMaterials.filter((_, i) => i !== index);

        if (updatedMaterials.length === 0) {
            setIsVisible(true);
        }

        setLocalMaterials(updatedMaterials); // Immediate UI update
        debouncedOnAddMaterial(updatedMaterials); // Propagate to parent state
    };


    const addNewRow = () => {
        const newMaterial = {
            name: "",
            quantity: "",
            unit: "",
            brands: [],
            specifications: "",
        };
        const updatedMaterials = [...localMaterials, newMaterial];

        setLocalMaterials(updatedMaterials); // Immediate UI update
        debouncedOnAddMaterial(updatedMaterials); // Propagate to parent state
    };


    const handleAddMaterialDetails = () => {
        setIsVisible(false);
        if (materials.length === 0) {
            addNewRow();
        }
    };

    useEffect(() => {
        if (materials.length === 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [materials]);

    useEffect(() => {
        if (JSON.stringify(materials) !== JSON.stringify(localMaterials)) {
            setLocalMaterials(materials); // Sync only if there's a real difference
        }
    }, [materials]);



    return (

        <>
            {isVisible ? (
                <button
                    onClick={handleAddMaterialDetails}
                    className="text-xs text-blue-500 underline italic pl-4 py-2"
                >
                    Add Material Details
                </button>
            ) : (
                <>
                    <div className="relative flex mt-2 pl-2">
                        <p className="inline-flex text-xs pb-2 text-gray-600 items-center">
                            Material required for work
                            <span className="ml-2 relative group bg-gray-200 rounded-full flex items-center justify-center w-4 h-4 text-[10px] cursor-pointer group-hover:text-gray-600">
                                i
                                <div className="absolute bottom-4 mt-1 hidden w-48 shadow-md border p-2 text-xs text-black bg-white rounded group-hover:block">
                                    If there are materials, they need to be provided.
                                </div>
                            </span>
                        </p>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="">
                            <div className=" w-full ">
                                <div className=" text-sm max-h-[510px]">
                                    {localMaterials.map((material, index) => (
                                        <div key={index} className="grid grid-cols-22 gap-x-1 mb-2">
                                            <p className="text-center text-xs">M{scopeIndex + 1}.{index + 1}</p>

                                            <div className="col-span-10">
                                                <ReactQuill
                                                    value={material?.name || ''}
                                                    // fieldName={`materials.${index}.name`}
                                                    onChange={(value) => handleInputChange(index, "name", value)}
                                                    theme="snow"
                                                    className="min-h-[28px] max-h-[60px] p-0 word-break whitespace-normal focus:outline-none focus:ring-0 text-sm w-full overflow-y-auto remove-quill-padding"
                                                    modules={{ toolbar: false }}
                                                />
                                                <ErrorMessage
                                                    name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${index}].name`}
                                                    component="div"
                                                    className="text-red-500 text-xs mt-1 self-end justify-end"
                                                />
                                            </div>

                                            <div className="col-span-2 self-start flex flex-col justify-end">
                                                <input
                                                    type="text"
                                                    name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${index}].quantity`}
                                                    value={material.quantity}
                                                    onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                                    className="border border-[#DCDCD0] focus:outline-none focus:ring-0 p-1 h-[28px] w-full text-sm"
                                                />
                                                <ErrorMessage
                                                    name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${index}].quantity`}
                                                    component="div"
                                                    className="text-red-500 text-xs mt-1"
                                                />
                                            </div>

                                            <div
                                                className="relative self-start col-span-3 flex flex-col justify-end text-sm">

                                                <SearchableDropdown
                                                    value={material.unit}
                                                    options={UNITOPTIONS}
                                                    onChange={(value) => handleInputChange(index, "unit", value)}
                                                    name={`materials.${index}.unit`}
                                                />
                                                <ErrorMessage
                                                    name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${index}].unit`}
                                                    component="div"
                                                    className="text-red-500 text-xs mt-1"
                                                />
                                            </div>

                                            <div className="col-span-5 self-start flex flex-col justify-end">
                                                <input
                                                    type="text"
                                                    value={material.brands.join(", ") || ''}
                                                    onChange={(e) => handleBrandInputChange(index, e.target.value)}
                                                    className="border border-[#DCDCD0] p-2 h-[28px] w-full text-sm focus:outline-none focus:ring-0"
                                                />
                                            </div>

                                            {/* <div className="col-span-8">
                                            <ReactQuill
                                                value={material?.specifications}
                                                onChange={(value) => handleInputChange(index, "specifications", value)}
                                                theme="snow"
                                                className="min-h-[30px] max-h-[60px] p-0 word-break whitespace-normal focus:outline-none focus:ring-0 text-sm w-full overflow-y-auto remove-quill-padding"
                                                modules={{ toolbar: false }}
                                            />
                                        </div> */}
                                            <div>
                                                <div className="col-span-1 flex items-center justify-evenly self-end">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="w-fit text-lg"
                                                            onClick={addNewRow}
                                                        >
                                                            {/* Plus Icon (Add) */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v14m7-7H5" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        {/* {materials.length > 1 && ( */}
                                                        <button
                                                            type="button"
                                                            onClick={() => removeRow(index)}
                                                            className="text-lg"
                                                        >
                                                            {/* Minus Icon (Remove) */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" >
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                                                            </svg>
                                                        </button>
                                                        {/* )} */}
                                                    </div>
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <StaticPopup
                        openModal={openUploadFile}
                        handleClose={() => setOpenUploadFile(false)}
                        size={"sm"}
                        width={"xl"}
                        bodyStyle="pb-0 pt-0"
                        className="px-0"
                        header={
                            <div className='text-base'>
                                Upload Files
                            </div>
                        }>

                        <div className="flex flex-col justify-center items-center px-0">
                            <div className="w-[400px] h-[180px] my-4 flex flex-col justify-center items-center border-2 border-dashed border-[#004491] bg-[#F1F7FF]">
                                <input
                                    id="file-upload-material"
                                    type="file"
                                    className="hidden"
                                    // onChange={handleFileUploadMaterial}
                                    accept=".xlsx, .xls"
                                />
                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" className='w-8 h-8 mb-2' />
                                <p className="text-sm whitespace-nowrap">
                                    Drag & Drop or
                                    <label htmlFor="file-upload-material" className="underline px-2 text-[#1D28FA] cursor-pointer">
                                        Choose File
                                    </label>
                                    to upload
                                </p>
                            </div>

                            {fileError && <div className="text-red-500 text-sm">{fileError}</div>}

                            <div>
                                <p className='text-sm text-center w-full font-normal text-[#777777]'>Don't have the template? </p>
                                <div className='flex items-center justify-center gap-2'>
                                    <a href={downloatTemplate} download className='text-sm text-center text-[#1D28FA] font-normal'>
                                        Download Here
                                    </a>
                                    <svg
                                        width="17"
                                        height="18"
                                        viewBox="0 0 17 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.5625 1.5625C9.5625 0.974805 9.0877 0.5 8.5 0.5C7.9123 0.5 7.4375 0.974805 7.4375 1.5625V9.6209L5.00039 7.18379C4.58535 6.76875 3.91133 6.76875 3.49629 7.18379C3.08125 7.59883 3.08125 8.27285 3.49629 8.68789L7.74629 12.9379C8.16133 13.3529 8.83535 13.3529 9.25039 12.9379L13.5004 8.68789C13.9154 8.27285 13.9154 7.59883 13.5004 7.18379C13.0854 6.76875 12.4113 6.76875 11.9963 7.18379L9.5625 9.6209V1.5625ZM2.125 12.1875C0.95293 12.1875 0 13.1404 0 14.3125V15.375C0 16.5471 0.95293 17.5 2.125 17.5H14.875C16.0471 17.5 17 16.5471 17 15.375V14.3125C17 13.1404 16.0471 12.1875 14.875 12.1875H11.5049L10.0008 13.6916C9.1707 14.5217 7.82598 14.5217 6.9959 13.6916L5.49512 12.1875H2.125ZM14.3438 14.0469C14.5551 14.0469 14.7578 14.1308 14.9072 14.2803C15.0567 14.4297 15.1406 14.6324 15.1406 14.8438C15.1406 15.0551 15.0567 15.2578 14.9072 15.4072C14.7578 15.5567 14.5551 15.6406 14.3438 15.6406C14.1324 15.6406 13.9297 15.5567 13.7803 15.4072C13.6308 15.2578 13.5469 15.0551 13.5469 14.8438C13.5469 14.6324 13.6308 14.4297 13.7803 14.2803C13.9297 14.1308 14.1324 14.0469 14.3438 14.0469Z"
                                            fill="blue"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-end px-0' style={{
                            boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)', // Adjust the shadow as needed
                        }}>
                            <button className='px-4 py-1 text-xs mx-4 my-2 bg-black text-white' onClick={() => setOpenUploadFile(false)}>
                                Upload
                            </button>
                        </div>
                    </StaticPopup>


                    <LoadingModal
                        isOpen={loading || fileError !== ""}
                        onClose={() => setFileError(null)} // Close the modal on click
                    >
                        {loading ? (
                            <div className="flex flex-col items-center justify-center">
                                {/* Circular spinner animation */}
                                <div className="animate-spin rounded-full border-4 border-t-4 border-orange-500 border-t-gray-200 h-8 w-8 mb-4"></div>
                                <p className="text-xl font-medium text-gray-600 italic">Adding materials from file, please wait...</p>
                            </div>
                        ) : (
                            <div>
                                <p>{fileError || "Materials uploaded successfully!"}</p>
                            </div>
                        )}
                    </LoadingModal>

                </>)}
        </>
    );
};

export default AddMaterialInScope;
