import * as XLSX from "xlsx";

export const parseBOQTemplate = (rows) => {
  const providingFixingDetails = [];
  let currentSection = null;

  rows.forEach((row, index) => {
    if (index === 0) return; // Skip header row

    const [sNoRaw, description, unit, quantity, brands] = row;
    const sNo = sNoRaw ? String(sNoRaw).trim() : null; // Convert sNo to string and trim

    if (sNo && /^S\d+$/.test(sNo)) {
      // New Section
      currentSection = {
        sectionName: description || `Section ${providingFixingDetails.length + 1}`,
        scopes: [],
      };
      providingFixingDetails.push(currentSection);
    } else if (sNo && /^\d+$/.test(sNo)) {
      // New Scope (integer sNo)
      const newScope = {
        scopeOfWork: description || "",
        quantity: quantity || "",
        unit: unit || "",
        preferredBrands: (brands || "").split(",").map((b) => b.trim()),
        materials: [],
      };
      currentSection?.scopes.push(newScope);
    } else if (sNo && /^\d+\.\d+$/.test(sNo)) {
      // New Material (decimal sNo)
      const parentScope = currentSection?.scopes[currentSection.scopes.length - 1];
      if (parentScope) {
        parentScope.materials.push({
          name: description || "",
          quantity: quantity || "",
          unit: unit || "",
          brands: (brands || "").split(",").map((b) => b.trim()),
          specifications: "",
        });
      }
    }
  });

  return providingFixingDetails;
};

export const validateSections = (sections) => {
  const errors = [];

  sections.forEach((section, index) => {
    if (!section.scopes || section.scopes.length === 0) {
      const sectionName = section.sectionName || `Section ${index + 1}`;
      errors.push(`In Section ${sectionName}, at least one scope is required.`);
    }
  });

  return errors;
};
