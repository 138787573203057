import { Button, Grid, Paper, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useRef, useState } from "react";
import AllFilters from "./AllFilters";
import { updateCompanyDirectory } from "utils/searchUtils";
import {
  clearCompanyDirFilters,
  filterCompanyDirectory,
} from "actions/companyDirectoryActions";
import { useDispatch, useSelector } from "react-redux";

const FilterSection = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const auth = useSelector((state) => state.auth);
  const [addedFilters, setAddedFilters] = useState([]);
  const [chips, setChips] = useState([]);
  const companyDirectory = useSelector((state) => state.companyDirectory);

  const userProfile = auth?.user;
  const userId = userProfile?.userId;
  const entityId = userProfile?.userEntity?.entityId;
  const appliedFilters = companyDirectory?.appliedFilters;
  const searchKey = companyDirectory?.searchKey;

  const removeChips = ({ key, value, isMultiple }) => {
    const filterData = updateCompanyDirectory({
      field: key,
      value,
      isMultiple,
      appliedFilters,
    });
    dispatch(
      filterCompanyDirectory({ filterData, searchKey, userId, entityId })
    );
  };

  const handleClearFilter = () => {
    dispatch(
      filterCompanyDirectory({ filterData: {}, searchKey, userId, entityId })
    );
  };
  useEffect(() => {
    let chipsData = [];
    Object.keys(appliedFilters).forEach((key) => {
      if (Array.isArray(appliedFilters[key])) {
        chipsData = [
          ...chipsData,
          ...appliedFilters[key].map((value) => ({
            key,
            value,
            isMultiple: true,
          })),
        ];
      } else {
        chipsData.push({ key, value: appliedFilters[key], isMultiple: false });
      }
    });
    setChips(chipsData);
  }, [appliedFilters]);
  return (
    <div
      style={{
        height: "100%",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
      }}
      ref={containerRef}
      className="overflow-auto bg-white w-[240px] rounded-lg"
    >
      <div className="flex justify-between p-3 sticky top-0 bg-white z-10">
        <p className="text-sm font-semibold text-gray-600">Filters</p>
        <div onClick={handleClearFilter} className="cursor-pointer">
          {chips?.length >= 1 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="text-gray-700 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>
      </div>

      <div className="flex flex-wrap sticky top-12 bg-white px-2">
        {chips?.map((chip) => (
          <div className="flex items-center max-w-32 bg-blue-100 border border-blue-500 rounded-full px-3 py-1 mr-2 mb-2">
            <span className="truncate text-sm  text-blue-600">
              {chip.key === "isPremium" ? "Verified Vendors" :  chip?.value}
            </span>
            <button
              onClick={() => removeChips(chip)}
              className="ml-1 flex-shrink-0 w-4 h-4  bg-blue-100 text-black hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-200"
            >
              <svg
                className="w-2 h-2 mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>

      <AllFilters containerRef={containerRef} />
    </div>
  );
};

export default FilterSection;
