import React from 'react';

const ContractorRFQTable = ({ rfqData, sortedSuppliers, withGst, openDropdown, handleMaterialDropdown }) => (
    rfqData?.providingFixingDetails?.map((providingFixingDetail, rowIndex) => (
        <tr key={rowIndex} className='bg-white'>
            <td className="bg-white text-xs z-10 max-w-[300px] min-w-[300px] sticky left-0 px-6" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}>
                <div className='space-x-2 w-full py-2 flex text-[#777777]'>
                    <p>{rowIndex + 1}.</p>
                    <p className='w-full whitespace-normal'>{providingFixingDetail?.sectionName}</p>
                </div>
            </td>
            {sortedSuppliers?.map((supplier, vendorIndex) => {
                const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                const vendorItem = vendorQuotation?.contractorDetails || [];

                // Find the matching section based on sectionName or ID
                const matchingSection = vendorItem.find(
                    (section) => section.sectionName === providingFixingDetail.sectionName
                );

                return (
                    <td key={vendorIndex} className={`min-w-[300px] px-4 z-0 border border-gray-300 ${vendorIndex < sortedSuppliers?.length - 1 ? 'border-r' : ''}`}>
                        <div onClick={() => handleMaterialDropdown(rowIndex, vendorIndex)} className='relative cursor-pointer py-1'>
                            <div>
                                {matchingSection ? (
                                    <p className="text-xs text-right w-full text-[#777777]">
                                        Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(matchingSection.sectionTotalExclGst || 0)}
                                    </p>
                                ) : (
                                    <p className="text-xs text-right w-full text-[#777777]">Rs. 0.00</p>
                                )}
                            </div>
                        </div>
                    </td>
                );
            })}
        </tr>
    ))
);

export default ContractorRFQTable;