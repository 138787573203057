import axios from "utils/axios";


// fileUploadHelpers.js
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

export const handleFileUploadClick = (event) => {
    return (event.target.value = "");
};

export const handleFileUpload = (event, selectedFilesTotalSize, setAttachmentsSkeleton, setSelectedFilesBase, setSelectedFilesTotalSize, setOpenFilesSizeError, setAttachmentsUploadsErrors) => {
    const files = event.target.files;
    // Count of currently selected files
    const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => {
        return acc + obj.size;
    }, 0);

    // Count of prev selected and current selected files
    const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce(
        (acc, obj) => acc + obj.FileSize,
        sizeOfSelectedFiles
    );

    // Validate file sizes and perform file upload as needed
    if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
        setAttachmentsSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

        const convertedFilesPromises = Array.from(files).map((file) => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("file", file);

                const reader = new FileReader();

                reader.onload = (e) => {
                    const fileContent = e.target.result;
                    const encryptedContent = btoa(fileContent); // Encrypt file content in Base64

                    // Fallback mechanism for .dwg files
                    let contentType = file.type;
                    if (!contentType && file.name.endsWith(".dwg")) {
                        contentType = "application/acad"; // Common MIME type for DWG files
                    }

                    // Include fileType, fileName, and encryptedContent in your request payload
                    const payload = {
                        Name: file.name,
                        Content: encryptedContent,
                        ContentType: contentType,
                        FileSize: file.size,
                    };
                    resolve(payload); // Resolve the promise with the payload for demonstration purposes
                };

                reader.readAsBinaryString(file);
            });
        });

        const attachmentBlobLink = (convertedFiles) => {
            axios
                .post("/api/rfqs/upload/attachments", convertedFiles)
                .then((response) => {
                    setSelectedFilesBase((prevVal) => [...prevVal, ...response.data]);
                    setSelectedFilesTotalSize((prevVal) => [
                        ...prevVal,
                        ...convertedFiles,
                    ]);
                    setAttachmentsSkeleton([]);
                })
                .catch((error) => {
                    setAttachmentsSkeleton([]);
                    setAttachmentsUploadsErrors(error.message);
                    setTimeout(() => {
                        setAttachmentsUploadsErrors("");
                    }, 5000);
                    console.error(error);
                });
        };

        Promise.all(convertedFilesPromises)
            .then((convertedFiles) => {
                attachmentBlobLink(convertedFiles);
            })
            .catch((error) => {
                console.error(error);
            });
    } else {
        setOpenFilesSizeError(true);
        setTimeout(() => {
            setOpenFilesSizeError(false);
        }, 6000);
    }
};

export const handleDeleteSelectedFile = (name, event, setSelectedFilesBase, setSelectedFilesTotalSize) => {
    event.preventDefault(); // Prevent default link behavior
    setSelectedFilesBase((prevVal) => prevVal.filter((data) => data.Name !== name));
    setSelectedFilesTotalSize((prevVal) => prevVal.filter((data) => data.Name !== name));
};

export const handleCloseFileSizeError = (setOpenFilesSizeError) => {
    setOpenFilesSizeError(false);
};
