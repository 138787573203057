import React, { useCallback, useMemo, useState } from "react";
import AddMaterialInScope from "./AddMaterialInScope"; // Adjust the import based on your project structure
import ReactQuill from "react-quill";
import SearchableDropdown from "./SearchableDropdown";
import { ErrorMessage } from "formik";
import StaticPopupNew from "components/StaticPopupNew";
import { debounce } from "lodash";
import { useFormikContext } from 'formik';


const SectionPopup = ({
  section,
  sectionIndex,
  expandedSections,
  // handleScopeChange,
  // handleAddScopeToSection,
  // handleRemoveScopeFromSection,
  handleClosePopup,
  setFieldValue,
  values,
  unitOptions,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(null);
  const { validateForm, setTouched } = useFormikContext();


  const handleScopeChange = (sectionIndex, scopeIndex, field, value) => {
    debouncedHandleScopeChange(sectionIndex, scopeIndex, field, value);
  };

  const debouncedHandleScopeChange = useCallback(
    debounce((sectionIndex, scopeIndex, field, value) => {
      const updatedSections = values.providingFixingDetails.map((section, secIdx) => {
        if (secIdx === sectionIndex) {
          const updatedScopes = section.scopes.map((scope, scIdx) =>
            scIdx === scopeIndex ? { ...scope, [field]: value } : scope
          );
          return { ...section, scopes: updatedScopes };
        }
        return section;
      });
      setFieldValue("providingFixingDetails", updatedSections);
    }, 100),
    [values, setFieldValue]
  );

  const handleRemoveScopeFromSection = (sectionIndex, scopeIndex) => {
    const updatedSections = values.providingFixingDetails.map((section, secIdx) => {
      if (secIdx === sectionIndex) {
        return {
          ...section,
          scopes: section.scopes.filter((_, scIdx) => scIdx !== scopeIndex),
        };
      }
      return section;
    });
    setFieldValue("providingFixingDetails", updatedSections);
  };

  // Add a scope to a specific section
  const handleAddScopeToSection = (sectionIndex) => {
    const updatedSections = values.providingFixingDetails.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          scopes: [
            ...section.scopes,
            {
              scopeOfWork: "",
              quantity: "",
              unit: "",
              preferredBrands: [],
              materials: []
            }
          ]
        };
      }
      return section;
    });
    setFieldValue("providingFixingDetails", updatedSections);
  };

  const handleSave = async () => {
    const validationErrors = await validateForm();
    const providingFixingDetailsErrors = validationErrors?.providingFixingDetails || [];
    const cleanedSections = values.providingFixingDetails.map((section) => {
      const cleanedScopes = section.scopes.map((scope) => {
        const cleanedMaterials = scope.materials.filter((material) => {
          return (
            material.name.trim() ||
            material.quantity ||
            material.unit.trim() ||
            (material.brands && material.brands.length > 0) ||
            material.specifications.trim()
          );
        });

        return {
          ...scope,
          materials: cleanedMaterials,
        };
      });

      return {
        ...section,
        scopes: cleanedScopes,
      };
    });
    setFieldValue("providingFixingDetails", cleanedSections);
    if (providingFixingDetailsErrors.length > 0) {
      const touchedFields = providingFixingDetailsErrors.map((sectionError, sectionIndex) => ({
        scopes: sectionError?.scopes?.map((scopeError, scopeIndex) => ({
          scopeOfWork: true,
          quantity: true,
          unit: true,
          materials: scopeError?.materials?.map(() => ({
            name: true,
            quantity: true,
            unit: true,
          })),
        })),
      }));
      setTouched({ providingFixingDetails: touchedFields });

      return;
    }
    handleClosePopup(sectionIndex);
  };



  return (
    <StaticPopupNew
      openModal={true}
      handleClose={() => handleClosePopup(sectionIndex)}
      width={"6xl"}
      height={50}
      bodyStyle="pb-0 pt-0 px-0"
      className="px-0 text-sm"
      header={section.sectionName}
      dismissible={false}
      showCloseButton={false}
    >
      <div className="mb-4 px-4">
        <div className="grid grid-cols-22 gap-1 border-b border-gray-300 sticky top-0 z-10 pr-2 pl-4 py-2 bg-gray-100">
          <p className="text-sm col-span-1">Sr#</p>
          <p className="text-sm col-span-10">Description (Work Details)</p>
          <p className="text-sm col-span-2">Qty</p>
          <p className="text-sm col-span-3">Unit</p>
          <div className="text-sm col-span-6 flex justify-between">
            <p>Preferred Brands (comma separated)</p>
          </div>
        </div>
        {section.scopes.map((scope, scopeIndex) => (
          <div key={scopeIndex} className="pt-2 border-b border-gray-300">
            <div className="grid grid-cols-22 gap-1">
              <p className="text-xs col-span-1 text-center">SoW {scopeIndex + 1}</p>
              <div className="col-span-10">
                <ReactQuill
                  value={scope.scopeOfWork}
                  onChange={(value) =>
                    handleScopeChange(sectionIndex, scopeIndex, "scopeOfWork", value)
                  }
                  theme="snow"
                  modules={{ toolbar: false }}
                  className="h-full max-h-[150px] overflow-y-auto remove-quill-padding"
                />
                <ErrorMessage
                  name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].scopeOfWork`}
                  component="div"
                  className="text-red-500 text-xs"
                />
              </div>
              <div className="col-span-2 self-end relative">
                <input
                  type="text"
                  value={section.scopes[scopeIndex].quantity || ""}
                  onChange={(e) =>
                    handleScopeChange(sectionIndex, scopeIndex, "quantity", e.target.value)
                  }
                  className="border focus:ring-0 border-gray-300 p-1 h-[28px] w-full text-xs"
                />
                <ErrorMessage
                  name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].quantity`}
                  component="div"
                  className="absolute text-red-500 text-xs mt-1"
                />
              </div>
              <div className="col-span-3 relative self-end">
                <SearchableDropdown
                  value={scope.unit}
                  options={unitOptions}
                  onChange={(value) => handleScopeChange(sectionIndex, scopeIndex, "unit", value)}
                />
                <ErrorMessage
                  name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].unit`}
                  component="div"
                  className="absolute text-red-500 text-xs mt-1"
                />
              </div>
              <div className="col-span-5 self-end">
                <ReactQuill
                  value={scope.preferredBrands.join(", ")}
                  onChange={(value) =>
                    handleScopeChange(
                      sectionIndex,
                      scopeIndex,
                      "preferredBrands",
                      value.split(", ")
                    )
                  }
                  theme="snow"
                  modules={{ toolbar: false }}
                  className="h-full max-h-[150px] self-end overflow-y-auto remove-quill-padding"
                />
                <ErrorMessage
                  name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].preferredBrands`}
                  component="div"
                  className="text-red-500 text-xs"
                />
              </div>
              <div className="flex justify-evenly self-end">
                <button type="button" onClick={() => handleAddScopeToSection(sectionIndex)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v14m7-7H5" />
                  </svg>
                </button>
                {section.scopes.length > 1 && (
                  <>
                    <button
                      type="button"
                      onClick={() => setShowConfirmation({ sectionIndex, scopeIndex })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                      </svg>
                    </button>

                    {showConfirmation?.sectionIndex === sectionIndex &&
                      showConfirmation?.scopeIndex === scopeIndex && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-40">
                          <div className="bg-white p-6 rounded shadow-md text-center">
                            <p className="text-sm mb-4">
                              Are you sure you want to delete this scope along with it's material list?</p>
                            <div className="flex justify-end gap-4">
                              <button
                                type="button"
                                onClick={() => {
                                  handleRemoveScopeFromSection(sectionIndex, scopeIndex);
                                  setShowConfirmation(null);
                                }}
                                className="bg-blue-500 text-white px-4 py-1 rounded"
                              >
                                Yes
                              </button>
                              <button
                                type="button"
                                onClick={() => setShowConfirmation(null)}
                                className="bg-gray-300 px-4 py-1 rounded"
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
            <div>
              <AddMaterialInScope
                materials={scope.materials}
                onAddMaterial={(updatedMaterials) => {
                  const updatedSections = values.providingFixingDetails.map((section, secIdx) => {
                    if (secIdx === sectionIndex) {
                      const updatedScopes = section.scopes.map((sc, scIdx) =>
                        scIdx === scopeIndex ? { ...sc, materials: updatedMaterials } : sc
                      );
                      return { ...section, scopes: updatedScopes };
                    }
                    return section;
                  });
                  setFieldValue("providingFixingDetails", updatedSections);
                }}
                sectionIndex={sectionIndex}
                scopeIndex={scopeIndex}
              />
            </div>
          </div>
        ))}
      </div>
      <div style={{
        boxShadow: "0px -2px 4px -2px rgba(0, 0, 0, 0.2)", // Top shadow
      }}
        className="w-full sticky bottom-0 bg-white rounded-b-lg border-t justify-between items-center text-sm flex px-4 py-2">

        <span className="italic text-xs">
          *SoW - Scope of Work
        </span>

        <div className="space-x-2">
          <button onClick={() => handleClosePopup(sectionIndex)} type="button" className="px-4 py-1 border hover:border-black">
            Cancel
          </button>
          <button onClick={handleSave} type="button" className="px-4 py-1 border hover:border-black">
            Save
          </button>

        </div>
      </div>
    </StaticPopupNew>
  );
};

export default SectionPopup;
