import { paginatedSearch } from "actions/searchActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterSection, TableSection } from "views/CompanyDirectory/components";
import NeevayNetwork from "views/NeevayNetwork";
import FilterPanel from "views/NeevayNetwork/components/FilterPanel";
import GlobalSearchBar from "views/NeevayNetwork/components/GlobalSearchBar";
import SearchNew from "views/NeevayNetwork/components/SearchNew";
import Table from "views/NeevayNetwork/components/Table";

const SelectVendorsSlider = ({
  rfqId,
  sliderName = "NN",
  openSlider,
  handleSliderClose,
  invitedVendors
}) => {
  const auth = useSelector((state) => state.auth);
  const user = auth?.user;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;

  const searchResult = useSelector((state) => state.search);
  const hasResults = searchResult.results && searchResult.results.length > 0;


  return (
    <div
      className={`fixed z-50 top-0 right-0 h-full bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] transition-transform duration-500 transform ${openSlider ? "translate-x-0" : "translate-x-full"
        }`}
      style={{ width: "calc(100% - 80px)" }}
    >

      {/* {openSlider && <NeevayNetwork />} */}
      {openSlider && sliderName == "NN" ? (
        <div>
          <div className="grid grid-cols-4 justify-between bg-white shadow-md items-center py-2 pl-5">
            <div className="flex items-center col-span-1">
              <button className="mr-2 hover:bg-gray-100" onClick={handleSliderClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="text-gray-700 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h1 className="text-base font-bold">Vendor Marketplace</h1>
            </div>
            <SearchNew />
            <div></div>
          </div>

          <div className="flex py-3 mt-2 pl-5 gap-x-5">
            {(hasResults || searchResult?.selectedFacets?.length > 1) && <div className="w-[260px]">
              <FilterPanel />
            </div>}

            <div
              // className="flex flex-col gap-y-4"
              style={{ height: "calc(100vh - 120px)" }}
              className="flex-grow overflow-x-auto pr-2"
            >
              <Table
                isSliderView={true}
                rfqId={rfqId}
                handleSliderClose={handleSliderClose}
                invitedVendors={invitedVendors}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-screen p-3 pr-0 gap-7">
          <div className="h-full flex flex-col" style={{ width: "260px" }}>
            <div className="p-4">
              {/* <p className="font-bold text-2xl">{directoryName} Directory</p> */}
              <p className="font-bold text-base">Internal Vendors</p>
            </div>
            <div className="flex-grow pl-2 overflow-x-auto overflow-y-auto">
              <FilterSection />
            </div>
          </div>

          <div className="flex-grow overflow-x-auto overflow-y-auto pr-2">
            <TableSection
              isSliderView={true}
              rfqId={rfqId}
              handleSliderClose={handleSliderClose}
              invitedVendors={invitedVendors}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectVendorsSlider;
