import React, { useEffect, useState } from "react";
import { FilterSection, RfqsTableSection } from "./Components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterRfqByDate,
  fetchRfqProjects,
  fetchRfqs,
  fetchSearchRfqs,
  saveRfq,
  saveRfqNew,
  searchRfqWithDate,
} from "actions/rfqActions";
import SelectVendorsSlider from "./Components/SelectVendorsSlider";
import SidePanel from "layouts/SidePanel";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import DateRangeComponent from "./DateRangeComponent";
import DateRangePicker from "./DateRangePicker ";
import Button from "../../components/Button";
import SlideInForm from "./Components/SlideInForm";
import RfqTypeMaterial from "./Components/RfqTypeMaterial";
import GeneralInformationNew from "./Components/GeneralInformationNew";
import LoadingModal from "./LoadingModal";
import RFQTypeDropDown from "./Components/RFQTypeDropDown";

const validationSchema = Yup.object({
  rfqName: Yup.string().required("Required"),
  requirementType: Yup.string().required("Required"),
  emailCopyRecipients: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .required("Required"),
  projectName: Yup.string().required("Required"),
  providingDate: Yup.date(),
  materials: Yup.array().when("requirementType", {
    is: "material",
    then: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Required"),
          quantity: Yup.number()
            .typeError("Quantity should be a number")
            .required("Required")
            .min(1, "Invalid quantity"),
          unit: Yup.string().required("Required"),
        })
      )
      .min(1, "At least one material is required")
      .required("Materials are required"),
    otherwise: Yup.array().notRequired(),
  }),
  labourDetails: Yup.array().when("requirementType", {
    is: "labour",
    then: Yup.array()
      .of(
        Yup.object().shape({
          skill: Yup.string().required("Required"),
          quantity: Yup.number()
            .required("Required")
            .min(1, "Must be at least 1"),
        })
      )
      .required("Labour details are required")
      .min(1, "At least one labour detail is required"),
    otherwise: Yup.array().notRequired(),
  }),
  providingFixingDetails: Yup.array().when("requirementType", {
    is: "contractor",
    then: Yup.array()
      .of(
        Yup.object().shape({
          sectionName: Yup.string().required("Section Name is required"),
          scopes: Yup.array()
            .of(
              Yup.object().shape({
                scopeOfWork: Yup.string().required("Required"),
                quantity: Yup.number().when("materials", {
                  is: (materials) => materials.length === 0,
                  then: Yup.number()
                    .typeError("Invalid")
                    .required("Required")
                    .min(0, "Invalid"),
                  otherwise: Yup.number().notRequired(),
                }),
                unit: Yup.string().when("materials", {
                  is: (materials) => materials.length === 0,
                  then: Yup.string().required("Required"),
                  otherwise: Yup.string().notRequired(),
                }),
                materials: Yup.array()
                  .of(
                    Yup.object().shape({
                      name: Yup.string().required("Required"),
                      quantity: Yup.number()
                        .typeError("Invalid")
                        .required("Required")
                        .min(0, "Invalid"),
                      unit: Yup.string().required("Required"),
                    })
                  )
                  .notRequired(),
              })
            )
            .min(1, "At least one scope is required")
            .required("Scopes are required"),
        })
      )
      .min(1, "At least one section is required")
      .required("Providing & Fixing Details are required"),
    otherwise: Yup.array().notRequired(),
  }),
});

const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
const RfqsSections = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const rfqData = useSelector((state) => state.rfq);
  // console.log("RFQDATA", rfqData)
  const rfqsData = useSelector((state) => state.rfqs);
  const auth = useSelector((state) => state.auth);
  const projectData = useSelector((state) => state.rfqProjects);

  const pendingApprovalsCount = rfqsData.rfqs.filter(rfq => rfq.status === "PENDING APPROVAL").length;

  const projects = projectData?.projects ?? [];
  const user = auth?.user;
  const isApprovalRequired = user?.userEntity?.features?.some(feature => feature.featureName === "APPROVAL_FLOW" && feature.hasFeature);

  const currentPage = location.pathname;
  const [rfqData, setRfqData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [workspaceView, setWorkspaceView] = useState("My Workspace");
  const [workspaceDropDownValue, setWorkspaceDropDownValue] = useState("All");
  const [hasMoreOptions, setHasMoreOptions] = useState(false);
  const [rfqFilterStatus, setRfqFilterStatus] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  const [openAddVendorOption, setOpenAddVendorOption] = useState(false);
  const [sliderName, setSliderName] = useState("");
  const [selectedFilesBase, setSelectedFilesBase] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([user?.userId]);
  const [dateFilter, setDateFilter] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [clearFilter, setClearFilter] = useState({ status: false, name: "" });
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedRequirementType, setSelectedRequirementType] = useState("");
  const [rfqFormValues, setRfqFormValues] = useState({
    rfqName: "",
    // estimatedValue: "",
    requirementType: "",
    // dueDate: "",
    providingDate: "",
    emailCopyRecipients: [],
    attachments: [],
    projectName: "",
    // projectLocation: "",
    // creditPeriod: "",
    rfqDetails: "",
    // indentId: "",
    materials: [{
      name: '',
      quantity: '',
      unit: '',
      brands: [],
      specifications: ''
    }],
    otherCharges: {},
    labourDetails: [],
    paymentTerms: '',
    termsAndConditions: "",
    providingFixingDetails: [
      {
        sectionName: "Section 1",
        scopes: [
          {
            scopeOfWork: "",
            quantity: "",
            unit: "",
            preferredBrands: [],
            materials: []
          }
        ]
      }
    ]
    ,
  });

  const today = new Date().toISOString().split("T")[0];
  // const loading = rfqData?.loading;
  const entityId = user?.userEntity?.entityId;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;
  const userId = user?.userId;
  const teamUserIds = user?.teamMembers?.map((member) => member.userId);

  const updateRfqFormValues = (updatedValues) => {
    setRfqFormValues(updatedValues);
  };

  const handleRequirementTypeSelect = (type) => {
    setRfqFormValues((prevValues) => ({
      ...prevValues,
      attachments: [],
      otherCharges: {},
      labourDetails: [],
      paymentTerms: '',
      materials: [{
        name: '',
        quantity: '',
        unit: '',
        brands: [],
        specifications: ''
      }],
      providingFixingDetails: [
        {
          sectionName: "Section 1",
          scopes: [
            {
              scopeOfWork: "",
              quantity: "",
              unit: "",
              preferredBrands: [],
              materials: []
            }
          ]
        }
      ],
      requirementType: type, // Update requirementType in state
    }));
    setShowDropdown(false);
    navigate(`/rfqs/create?rfqType=${type}`); // Update the URL
    setShowForm(true); // Open the form
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


  const handleSubmit = async (values, { setSubmitting }) => {

    const selectedProject = projects.find(
      (project) => project._id === values.projectName
    );
    const formValue = {
      ...values,
      projectId: selectedProject._id,
    };

    setLoading(true); // Show loading spinner

    try {
      const saveResult = await saveRfqNew({ entityId, values: formValue });

      // If save is successful, set the RFQ data
      if (saveResult.error) {
        setError(saveResult.message); // Set the error message if error is true
      } else {
        setRfqData(saveResult.payload); // Set RFQ data to state
        setError(null); // Reset error if the RFQ is saved successfully
      }
    } catch (error) {
      console.error("Error saving RFQ:", error);
      setError("Failed to save RFQ. Please try again.");
    } finally {
      setLoading(false); // Close the loading spinner
    }
  };
  const toggleForm = () => {
    setShowForm(!showForm);
    if (showForm) {
      navigate("/rfqs");
    } else {
      navigate("/rfqs/create");
    }
  };

  const handleSliderOpen = (title) => {
    setSliderName(title);
    setOpenSlider(true);
  };
  const handleSliderClose = () => {
    setOpenSlider(false);
  };

  const handleGetRfqs = ({
    status = "",
    searchKey = rfqsData?.searchParam,
  }) => {
    if (searchKey === "" && startDate === "" && endDate === "") {
      dispatch(fetchRfqs({ entityId, userIds: teamMemberData, status }));
      return;
    }
    if (startDate != "" && endDate != "") {
      fetchFilterDateRfq({ page: 1, status, searchKey });
    } else {
      // dispatch(fetchRfqs({ entityId, userIds: teamMemberData, status }));
      dispatch(
        fetchSearchRfqs({
          entityId,
          searchParam: searchKey,
          status,
          userIds: teamMemberData,
        })
      );
    }
  };
  const handleCloseRfqForm = () => {
    setRfqFormValues({
      rfqName: "",
      // estimatedValue: "",
      attachments: [],
      requirementType: "",
      dueDate: new Date(),
      emailCopyRecipients: [],
      projectName: "",
      projectLocation: "",
      creditPeriod: "",
      rfqDetails: "",
    });
    fetchPersonalRfqs();
    setOpenAddVendorOption(false);
  };

  const handleChangeTeamMember = (event) => {
    const selectedUserId = event.target.value;
    setStartDate("");
    setClearFilter({ status: true, name: "team member" });
    setEndDate(today);
    setDateFilter(false);
    setWorkspaceDropDownValue(selectedUserId);
    if (selectedUserId === "All") {
      fetchAllRfqs();
    } else if (selectedUserId === "Team") {
      dispatch(
        fetchRfqs({ entityId, userIds: teamUserIds, status: rfqFilterStatus })
      );
      setTeamMemberData(teamUserIds);
    } else {
      dispatch(
        fetchRfqs({
          entityId,
          userIds: [selectedUserId],
          status: rfqFilterStatus,
        })
      );
      setTeamMemberData([event.target.value]);
    }
  };

  const handleChangeTeamMemberNew = (selectedUserId) => {
    dispatch(
      searchRfqWithDate({
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds:
          selectedUserId.length == 0
            ? [...teamUserIds, user?.userId]
            : selectedUserId,
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData(selectedUserId);
  };

  const fetchAllRfqs = () => {
    setWorkspaceView("Team Workspace");
    setStartDate("");
    // setClearFilter({ status: true, name: "all rfq" });
    setEndDate(today);
    setDateFilter(false);
    dispatch(
      searchRfqWithDate({
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds: [...teamUserIds, user?.userId],
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData([...teamUserIds, user?.userId]);
    setWorkspaceDropDownValue("All");
  };

  const fetchPendingApprovalRfqs = () => {
    setWorkspaceView("Pending Approvals");
    setRfqFilterStatus("PENDING APPROVAL");
    setStartDate("");
    // setClearFilter({ status: true, name: "all rfq" });
    setEndDate(today);
    setDateFilter(false);
    dispatch(
      searchRfqWithDate({
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: "PENDING APPROVAL",
        userIds: [...teamUserIds, user?.userId],
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData([...teamUserIds, user?.userId]);
    // setWorkspaceDropDownValue("All");
  };

  const fetchPersonalRfqs = () => {
    setWorkspaceView("My Workspace");
    setStartDate("");
    // setClearFilter({ status: true, name: "fetchPersonalRfqs" });
    // setEndDate(today);
    // setDateFilter(false);

    dispatch(
      searchRfqWithDate({
        entityId,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        searchParam: rfqsData?.searchParam,
        status: rfqsData?.filterStatus,
        userIds: [userId],
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
      })
    );
    setTeamMemberData([user?.userId]);
    setWorkspaceDropDownValue("All");
  };

  const fetchFilterDateRfq = ({
    page = 1,
    status = rfqFilterStatus,
    searchKey,
  } = {}) => {
    dispatch(
      searchRfqWithDate({
        entityId,
        searchParam: searchKey,
        status,
        userIds: teamMemberData.map((number) => String(number)),
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
      })
    );
  };

  const handleApplyFilterDate = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    // dispatch(FilterRfqByDate(payload));
    dispatch(
      searchRfqWithDate({
        entityId,
        searchParam: rfqsData?.searchParam,
        status: rfqFilterStatus,
        userIds: teamMemberData.map((number) => String(number)),
        startDate,
        endDate,
      })
    );
  };
  const clearDateFilter = () => {
    // dispatch(
    //   fetchRfqs({ entityId, userIds: [userId], status: rfqFilterStatus })
    // );
    dispatch(
      fetchSearchRfqs({
        entityId,
        searchParam: rfqsData?.searchParam,
        status: rfqFilterStatus,
        userIds: [userId],
      })
    );
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    dispatch(
      fetchRfqs({ entityId, userIds: [userId], status: rfqFilterStatus })
    );
    dispatch(fetchRfqProjects(entityId));
    if (currentPage === "/rfqs/create") {
      setShowForm(true);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const rfqType = queryParams.get("rfqType");

    if (rfqType) {
      setRfqFormValues((prevValues) => ({
        ...prevValues,
        requirementType: rfqType, // Update the requirementType based on URL
      }));
      setShowForm(true); // Open the form if rfqType exists
    }
  }, []);


  useEffect(() => {
    if (currentPage === "/rfqs/create") {
      setShowForm(true);
    }
    if (currentPage === "/rfqs") {
      setShowForm(false);
    }
  }, [currentPage]);

  return (
    <SidePanel>
      <div >
        <div className="bg-white sticky top-0 z-10 shadow-md p-4">
          <h1 className="text-base font-bold">Requirements</h1>
        </div>
        <div className="py-2 px-5 min-h-[calc(100vh-64px)] bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] ">
          <div className="w-full flex gap-4">
            <div className="min-w-[200px] h-10">
              {/* {user?.teamMembers?.length > 0 &&
                workspaceView === "Team Workspace" && (
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <Select
                      id="demo-simple-select-helper"
                      value={workspaceDropDownValue}
                      onChange={handleChangeTeamMember}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"Team"}>Team</MenuItem>
                      {user?.teamMembers?.map((member) => (
                        <MenuItem value={member.userId} key={member.userId}>
                          {member?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )} */}
            </div>
            <div className="w-full flex items-end">
              {user?.teamMembers?.length > 0 && (
                <div className="flex gap-4">
                  <button
                    className={`pb-2 ${workspaceView === "My Workspace"
                      ? " border-b border-orange-500 font-medium"
                      : ""
                      } `}
                    onClick={fetchPersonalRfqs}
                  >
                    <p className="text-sm">My Workspace</p>
                  </button>
                  <button
                    className={`pb-2 ${workspaceView === "Team Workspace"
                      ? " border-b border-orange-500 font-medium"
                      : ""
                      } `}
                    onClick={fetchAllRfqs}
                  >
                    <p className="text-sm">Team Workspace</p>
                  </button>
                  {isApprovalRequired && <button
                    className={`pb-2 ${workspaceView === "Pending Approvals"
                      ? " border-b border-orange-500 font-medium"
                      : ""
                      } `}
                    onClick={fetchPendingApprovalRfqs}
                  >
                    <p className="text-sm">Pending Approvals{pendingApprovalsCount > 0 ? ` (${pendingApprovalsCount})` : ""}</p>
                  </button>}
                </div>
              )}
              {/* {!dateFilter ? (
                <button
                  type="button"
                  className="m-3 rounded-md border border-blue-700 text-blue-700 hover:text-blue-800 hover:border-blue-800 shadow-sm px-2 py-1 text-sm font-medium focus:outline-none"
                  onClick={() => {
                    setDateFilter(true);
                    setEndDate(today);
                  }}
                >
                  Date Range
                </button>
              ) : (
                <div className="m-3 my-0 rounded-lg flex gap-2 items-center p-1 relative">
                  <div className="flex flex-col">
                    <input
                      type="date"
                      id="start-date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      max={new Date().toISOString().split("T")[0]}
                      className="border border-gray-300 rounded-md p-2"
                    />
                  </div>
                  <p>To</p>
                  <div className="flex flex-col">
                    <input
                      type="date"
                      id="end-date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      max={new Date().toISOString().split("T")[0]}
                      className="border border-gray-300 rounded-md p-2"
                    />
                  </div>
                </div>
              )} */}
              {/* <DateRangePicker
                handleApplyFilterDate={handleApplyFilterDate}
                clearDateFilter={clearDateFilter}
                clearFilter={clearFilter}
              /> */}

              <button
                className="cursor-pointer mb-2 ml-auto flex items-center justify-center px-4 py-2 text-xs font-medium bg-[#1F1E29] text-white rounded-sm"
                onClick={toggleDropdown}
              >
                <svg
                  className="h-3.5 w-3.5 mr-2 -ml-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Create Requirement
              </button>
              <RFQTypeDropDown
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                handleRequirementTypeSelect={handleRequirementTypeSelect}
              />

            </div>
          </div>
          <div className="flex gap-4 max-h-[calc(100vh-144px)]">
            <div className="min-w-[200px] bg-white">
              <FilterSection
                handleChangeTeamMemberNew={handleChangeTeamMemberNew}
                workspaceView={workspaceView}
                rfqsData={rfqsData}
                handleGetRfqs={handleGetRfqs}
                setRfqFilterStatus={setRfqFilterStatus}
                user={user}
                handleApplyFilterDate={handleApplyFilterDate}
                clearDateFilter={clearDateFilter}
                clearFilter={clearFilter}
              />
            </div>
            <div className="flex-grow bg-white rounded overflow-y-auto">
              <RfqsTableSection
                handleGetRfqs={handleGetRfqs}
                workspaceView={workspaceView}
                entityId={entityId}
                userIds={teamMemberData}
                rfqsData={rfqsData}
                rfqFilterStatus={rfqFilterStatus}
                user={user}
                fetchFilterDateRfq={fetchFilterDateRfq}
                dateFilterOn={startDate != "" && endDate != ""}
                startDate={startDate}
                endDate={endDate}
                isApprovalRequired={isApprovalRequired}
              />
            </div>
          </div>
        </div>

        {/* <SlideInForm showForm={showForm} closeForm={() => setShowForm(false)}> */}
        <div
          className={`fixed inset-0 z-30 flex justify-end transition-all duration-500 ${showForm ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
        >
          <div className="fixed inset-0 bg-black opacity-50" />
          <div
            className={`fixed z-20 top-0 right-0 h-full w-[94%] bg-white transition-transform duration-500 transform ${showForm ? "translate-x-0" : "translate-x-full"
              }`}
          >
            {/* <div className="fixed inset-0 bg-black opacity-50"  /> */}

            <Formik
              enableReinitialize
              initialValues={{
                ...rfqFormValues,
                // workStartDate: Date(Date.now()),
                requesterName: user?.name || "",
                requesterMobile: user?.mobile || "",
                buyerPublicContact: user?.publicContact || "",
                userBusinessName: user?.userEntity
                  ? user?.userEntity.entityName
                  : user?.company || "",
                requestedBy: user?.email || "",
                requesterId: user?.userId || "",
                isEntityUser: user?.userEntity ? true : false,
                // rfqDetails: "rfqDetails"
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, submitForm, errors, resetForm }) => (
                <Form className="h-full justify-start">

                  {/* header */}
                  <div className="sticky top-0 bg-white z-20">
                    <div className="flex px-8 py-3 justify-between items-center">
                      <h1 className="text-neevay-theme-text-600 font-medium text-xl">
                        Requirement form for {values?.requirementType === "labour" ?  "Labour/Item Rate" : capitalizeFirstLetter(values?.requirementType )}
                      </h1>
                      <Button
                        variant="text"
                        className="text-gray-600 text-sm flex items-center gap-x-2"
                        style={{ background: "#FBFBF9" }}
                        onClick={() => {
                          resetForm()
                          setRfqFormValues({
                            rfqName: "",
                            requirementType: "",
                            providingDate: "",
                            emailCopyRecipients: [],
                            attachments: [],
                            projectName: "",
                            rfqDetails: "",
                            materials: [{
                              name: '',
                              quantity: '',
                              unit: '',
                              brands: [],
                              specifications: ''
                            }],
                            otherCharges: {},
                            labourDetails: [],
                            providingFixingDetails: "",
                            paymentTerms: '',
                            providingFixingDetails: [
                              {
                                sectionName: "",
                                scopes: [
                                  {
                                    scopeOfWork: "",
                                    quantity: "",
                                    unit: "",
                                    preferredBrands: [],
                                    materials: []
                                  }
                                ]
                              }
                            ]

                          });
                          setShowForm(false);
                          navigate("/rfqs");
                        }}
                      >
                        Close
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L11 11M11 1L1 11"
                            stroke="#4F4F42"
                            stroke-linecap="round"
                          />
                        </svg>
                      </Button>
                    </div>
                    <div className=" mb-2">
                      <hr />
                    </div>
                  </div>

                  <div className="h-full overflow-y-auto pb-[100px]">
                    <GeneralInformationNew
                      projects={projects}
                      updateFormValues={updateRfqFormValues}
                      entityId={entityId}
                    />
                  </div>
                  {/* 
                   <RfqDetailsSection
                   selectedFilesBase={selectedFilesBase}
                   setSelectedFilesBase={setSelectedFilesBase}
                   fieldName="rfqDetails"
                   updateFormValues={updateRfqFormValues}
                   /> */}

                  {/* footer */}
                  <div
                    className="px-3 py-4 sticky bottom-0 z-20 bg-white flex justify-between items-center shadow-md "
                    style={{
                      boxShadow: "0px -4px 4px 0px #0000001A",
                    }}
                  >
                    {/* <Button
                  variant="text"
                  className="text-neevay-theme-text-400 flex items-center gap-x-2 hover:text-neevay-theme-text-500"
                  onClick={() => {
                    setHasMoreOptions(!hasMoreOptions);
                  }}
                  style={{ background: "#F5F5F3" }}
                  type="button"
                >
                  {hasMoreOptions ? "Less Options" : "More Options"}
                </Button> */}
                    {/* {!openAddVendorOption && (
                  <button
                    type="button"
                    className="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded-lg mr-2"
                    disabled={loading} // Disable the button when loading is true
                    onClick={async (e) => {
                      e.preventDefault();
                      await submitForm();
                      const firstErrorField = Object.keys(errors)[0];
                      const errorElement =
                        document.getElementById(firstErrorField);
                      if (errorElement) {
                        errorElement.scrollIntoView({
                          behavior: "smooth",
                        });
                      } else if (values.rfqName) {
                        setShowForm(false);
                        navigate("/rfqs");
                        handleCloseRfqForm();
                      }
                    }}
                  >
                    {loading ? "Saving Draft..." : "Save as Draft"}
                  </button>
                )} */}
                    <div className="space-x-2 sticky bottom-0 flex justify-end w-full px-8">

                      <button
                        type="button"
                        className="px-4 border rounded-sm border-[#1E1E08] text-sm w-fit whitespace-nowrap py-2 hover:bg-[#1E1E08] hover:text-white mr-2 "
                        disabled={loading || rfqData?.rfqId} // Disable the button when loading is true
                        onClick={async (e) => {
                          e.preventDefault();
                          await submitForm();
                          const firstErrorField = Object.keys(errors)[0];
                          const errorElement =
                            document.getElementById(firstErrorField);
                          if (errorElement) {
                            errorElement.scrollIntoView({
                              behavior: "smooth",
                            });
                          } else if (values.rfqName) {
                            if (Object.keys(errors).length === 0) {
                              setShowForm(false);
                              navigate("/rfqs");
                              setRfqFormValues({})
                              setRfqData(null)
                              handleCloseRfqForm();
                            }
                          }
                        }}
                      >
                        {loading ? "Saving Draft..." : "Save as Draft"}
                      </button>

                      <button
                        className={`border hover:bg-black hover:text-white hover:border-black ${loading
                          ? " bg-gray-300"
                          : " bg-[#1E1E08] hover:bg-[#1A1A02]"
                          } text-white rounded-sm px-4 py-2 font-medium flex justify-evenly items-center`}
                        type="button"
                        disabled={loading || rfqData?.rfqId}
                        onClick={async (e) => {
                          e.preventDefault();
                          await submitForm();

                          const firstErrorField = Object.keys(errors)[0];
                          const errorElement =
                            document.getElementById(firstErrorField);
                          if (errorElement) {
                            errorElement.scrollIntoView({
                              behavior: "smooth",
                            });
                          } else if (values.rfqName) {
                            if (Object.keys(errors).length === 0) {
                              setOpenAddVendorOption(true);
                            }
                          }
                          // updateRfqFormValues(values);
                          // setPressSaveOnly(false);
                        }}
                      >
                        {/* {loading ? "Loading..." : "Save and Find Vendors"} */}
                        <span className="text-sm font-normal">Save & Invite Vendors</span>
                        {/* <hr className="rotate-90 w-[13px] h-[1px] border-none bg-[#969696] my-2" />
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_4661_4356)">
                            <path
                              d="M4.98894 2.5C5.22762 2.50005 5.4565 2.5877 5.62524 2.74367L9.22524 6.07019C9.3112 6.1469 9.37976 6.23867 9.42693 6.34013C9.4741 6.44159 9.49893 6.55072 9.49997 6.66114C9.501 6.77157 9.47823 6.88108 9.43298 6.98328C9.38773 7.08548 9.3209 7.17834 9.2364 7.25642C9.15189 7.3345 9.05141 7.39626 8.9408 7.43807C8.83019 7.47989 8.71168 7.50093 8.59218 7.49997C8.47268 7.49901 8.35458 7.47607 8.24478 7.43248C8.13497 7.3889 8.03566 7.32554 7.95264 7.24611L4.98894 4.50755L2.02524 7.24611C1.8555 7.3976 1.62816 7.48142 1.39218 7.47953C1.1562 7.47763 0.930473 7.39017 0.763606 7.23598C0.596738 7.08179 0.502085 6.87321 0.500035 6.65516C0.497984 6.43711 0.588699 6.22704 0.752641 6.07019L4.35264 2.74367C4.52139 2.5877 4.75027 2.50005 4.98894 2.5Z"
                              fill="#FD6600"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4661_4356">
                              <rect
                                width="10"
                                height="10"
                                fill="white"
                                transform="matrix(-1 0 0 -1 10 10)"
                              />
                            </clipPath>
                          </defs>
                        </svg> */}
                      </button>

                      {/* // up dropdown of company directory and neevay network -----------------------start5 from here */}
                      {openAddVendorOption &&
                        !loading &&
                        rfqData?.rfqId > 0 && (
                          <div className="absolute rounded-lg py-2 bottom-[110%] right-0 w-52 bg-white border  border-gray-500">
                            <div
                              className="py-2 pl-3 hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleSliderOpen("CD")}
                            >
                              <p className="text-left font-medium text-gray-900">
                                {directoryName} Directory{" "}
                              </p>
                            </div>
                            <div
                              className="py-2 pl-3 flex items-center hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleSliderOpen("NN")}
                            >
                              <div
                                style={{
                                  maxWidth: "250px",
                                  marginRight: "8px",
                                }}
                              >
                                <p className="font-medium text-gray-900">
                                  Neevay Network{" "}
                                </p>
                              </div>

                              <div
                                style={{
                                  borderRadius: "3px",
                                  border: "0.5px solid #039400",
                                  background: "#E1FFDE",
                                  padding: "0 4px", // You can adjust the padding as needed
                                }}
                                className="flex items-center"
                              >
                                <span className="text-black font-inter font-semibold text-xs ">
                                  N
                                </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="5"
                                  height="5"
                                  viewBox="0 0 5 5"
                                  fill="none"
                                >
                                  <circle
                                    cx="2.5"
                                    cy="2.5"
                                    r="2.5"
                                    fill="#039400"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        )}
                      {/* // up dropdown of company directory and neevay network -----------------------end from here */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

          </div>
        </div>
        {/* </SlideInForm> */}
        <SelectVendorsSlider
          rfqId={rfqData?.rfqId}
          sliderName={sliderName}
          openSlider={openSlider}
          setOpenSlider={setOpenSlider}
          handleSliderClose={handleSliderClose}
        />
      </div>

      <LoadingModal
        isOpen={loading || error !== null}
        onClose={() => setError(null)} // Close the modal on click
      >
        {loading ? (
          <div className="flex flex-col items-center justify-center">
            {/* This is a circular spinner centered in the modal */}
            <div className="animate-spin rounded-full border-4 border-t-4 border-orange-500 border-t-gray-200 h-8 w-8 mb-4"></div>
            <p className="text-xl font-medium  text-gray-600 italic">Sit tight, we are creating RFQ for you...</p>
          </div>
        ) : (
          <div>
            <p>{error || "RFQ saved successfully!"}</p>
          </div>
        )}
      </LoadingModal>


    </SidePanel>
  );
};

export default RfqsSections;
